import RareModalView from './RareModalView';

export default class
{
    constructor($rootScope, $compile, $http) {
        this.$rootScope = $rootScope;
        this.$compile = $compile;
        this.$http = $http;
    }

    /**
     * Create the Modal View
     *
     * @param  {String} templateString The HTML to add to the modal
     * @param  {Object} options
     * @return {RareModalView}
     */
    createModal(templateString, options = {}) {
        // Create a new scope for the modal
        const scope = options.scope && options.scope.$new() || this.$rootScope.$new(true);

        // Compile the template
        const element = this.$compile(`<rare-modal>${templateString}</rare-modal>`)(scope);

        /* eslint-disable no-param-reassign */
        options.$el = element;
        options.el = element[0];
        options.modalEl = options.el.querySelector('.rare-modal-backdrop');
        /* eslint-enable no-param-reassign */

        const modal = new RareModalView(options, scope);
        modal.scope = scope;

        return modal;
    }

    /**
     * Create a Modal View from a template URL
     *
     * @param  {String} url     Where to load the template from
     * @param  {Object} options
     * @return {Promise}         Resolves to a RareModalView object
     */
    fromTemplateUrl(url, options = {}) {
        return this.$http.get(url).then((response) => {
            return response.data && response.data.trim();
        }).then((templateString) => {
            return this.fromTemplate(templateString, options);
        });
    }

    /**
     * Create a Modal View from a template string
     *
     * @param  {String} template Template string as HTML
     * @param  {Object} options
     * @return {RareModalView}
     */
    fromTemplate(template, options = {}) {
        return this.createModal(template, options);
    }
}
