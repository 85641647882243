import trim from './helpers/trim';

export default () => {
    return {
        inbound(input) {
            return {
                id: trim(input.PortId),
                status: input.Status,
                name: trim(input.PortName),
                country: {
                    code: trim(input.CountryCode),
                    name: trim(input.CountryName),
                },
            };
        },
    };
};
