/**
 * Reduces an array of country objects to a unique list
 *
 * {
 *     name: 'United Kingdom',
 *     isoCode: 'GB',
 * }
 *
 * @param  {Array} countries
 * @return {Array}
 */
export default (countries) => {
    const uniqueCountries = [];

    countries.forEach((country) => {
        const codeExists = uniqueCountries.filter((item) => {
            return item.isoCode === country.isoCode;
        }).length > 0;

        if (codeExists) {
            return;
        }

        uniqueCountries.push(country);
    });

    return uniqueCountries;
};
