import trim from './helpers/trim';

export default () => {
    return {
        inbound(input) {
            return {
                id: trim(input.ContactID),
                country: {
                    code: trim(input.ISO3Code),
                    name: trim(input.Country),
                },
                fullname: trim(input.FullName),
                job: {
                    title: trim(input.JobTitle),
                    category: trim(input.JobCategory),
                },
                sortOrder: input.SortOrder,
                telephone: trim(input.Telephone),
                fax: trim(input.Fax),
                mobile: trim(input.MobilePhone),
                email: trim(input.EmailAddress),
                port: trim(input.Port),
                organisation: {
                    id: trim(input.OrganisationID),
                    name: trim(input.Organisation),
                },
            };
        },
    };
};

