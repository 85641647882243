export default class
{
    constructor(AppConfig, $q) {
        this.$q = $q;
        this.apiDetails = AppConfig.api.auth;
    }

    init() {
        this.credentials = this.retrieveCredentials();
    }

    isAuthenticated() {
        return this.credentials.accessToken;
    }

    getAuthorizationHeader() {
        if (!this.isAuthenticated()) {
            return false;
        }

        return `Bearer ${this.credentials.accessToken}`;
    }

    user() {
        if (!this.isAuthenticated()) {
            return false;
        }

        return {
            displayName: this.credentials.userInfo.displayableId,
            givenName: this.credentials.userInfo.givenName,
            id: this.credentials.userInfo.userId,
            uniqueId: this.credentials.userInfo.uniqueId,
        };
    }

    token() {
        if (!this.isAuthenticated()) {
            return false;
        }

        return {
            accessToken: this.credentials.accessToken,
            expiresOn: this.credentials.expiresOn,
        };
    }

    authenticate() {
        return this.$q((resolvePromise, rejectPromise) => {
            const successCallback = (authResponse) => {
                this.saveCredentials(authResponse);
                resolvePromise(authResponse);
            };

            let context = new Microsoft.ADAL.AuthenticationContext(this.apiDetails.authority);

            context.tokenCache.readItems().then((items) => {
                let userId = null;

                if (items.length > 0) {
                    this.apiDetails.authority = items[0].authority;
                    userId = items[0].userInfo.userId;

                    context = new Microsoft.ADAL.AuthenticationContext(
                        this.apiDetails.authority
                    );
                }

                // Attempt to authorize user silently
                const aquireSilentToken = context.acquireTokenSilentAsync(
                    this.apiDetails.resourceUri,
                    this.apiDetails.clientId,
                    userId
                );

                aquireSilentToken.then(successCallback, () => {
                    // We require user credentials so triggers authentication dialog
                    const aquireToken = context.acquireTokenAsync(
                        this.apiDetails.resourceUri,
                        this.apiDetails.clientId,
                        this.apiDetails.redirectUri
                    );

                    aquireToken.then(successCallback, rejectPromise);
                });
            });
        });
    }

    refreshToken() {
        if (!window.Microsoft || !window.Microsoft.ADAL) {
            return this.$q.reject();
        }

        return this.$q((resolvePromise, rejectPromise) => {
            const successCallback = (authResponse) => {
                console.log('Token refreshed');

                this.saveCredentials(authResponse);
                resolvePromise(authResponse);
            };

            let context = new Microsoft.ADAL.AuthenticationContext(this.apiDetails.authority);

            context.tokenCache.readItems().then((items) => {
                let userId = null;

                if (items.length > 0) {
                    this.apiDetails.authority = items[0].authority;
                    userId = items[0].userInfo.userId;

                    context = new Microsoft.ADAL.AuthenticationContext(
                        this.apiDetails.authority
                    );
                }

                // Attempt to authorize user silently
                const aquireSilentToken = context.acquireTokenSilentAsync(
                    this.apiDetails.resourceUri,
                    this.apiDetails.clientId,
                    userId
                );

                aquireSilentToken.then(successCallback, (error) => {
                    // We've failed to refresh the token. If this is because our tokenCache is
                    // empty then we have no chance ot getting these back so lets remove the
                    // auth details we have
                    this.saveCredentials({});

                    rejectPromise(error);
                });
            });
        });
    }

    logout() {
        this.saveCredentials(null);
        this.init();
    }

    saveCredentials(credentials) {
        this.credentials = credentials;
        localStorage.setItem('credentials', JSON.stringify(credentials));
    }

    retrieveCredentials() {
        return JSON.parse(localStorage.getItem('credentials')) || {};
    }
}
