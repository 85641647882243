export default class
{
    constructor(API, Inspections, $q, $rootScope, Auth) {
        this.API = API;
        this.Auth = Auth;
        this.Inspections = Inspections;
        this.$q = $q;

        this.flushing = false;

        $rootScope.$on('$cordovaNetwork:online', () => {
            this.flush();
        });
    }

    add(inspection) {
        return this.Inspections.store(inspection).then(() => {
            return this.flush();
        });
    }

    /**
     * Attempt to send all inspections to the API
     */
    flush() {
        if (this.flushing) {
            return this.flushPromise;
        }

        this.flushing = true;

        this.flushPromise = this.Inspections.all().then((inspections) => {
            const queueLength = inspections.length;
            const inspectionsSent = [];
            let permissionError = false;

            // Attempt to send each of the stored inspections to the API
            this.promises = inspections.map((inspection) => {
                return this.API.postInspection(inspection).then(() => {
                    // On success, add this inspection item to the list to be removed from the queue
                    inspectionsSent.push(inspection);
                }).catch((error) => {
                    if (error.status === 401) {
                        permissionError = true;
                    }
                });
            });

            // Whether all calls succeed of fail we want to remove successfully sent items from the
            // database
            return this.$q.all(this.promises).finally(() => {
                return this.$q.all(inspectionsSent.map((inspection) => {
                    return this.Inspections.delete(inspection);
                }));
            }).then(() => {
                return Promise.resolve(queueLength === inspectionsSent.length);
            }).finally(() => {
                this.flushing = false;

                if (permissionError) {
                    this.Auth.logout();
                }
            });
        });

        return this.flushPromise;
    }
}
