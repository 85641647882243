import uniqueCountries from '../helpers/uniqueCountries';

export default class
{
    constructor(Database, $q) {
        this.$q = $q;
        this.Database = Database;
    }

    fetch(id) {
        return this.$q.when(this.Database.get('inspectors', id));
    }

    allForCountryCode(countryCode) {
        const promise = this.Database
            .from('inspectors')
            .where('country.code', '=', countryCode)
            .list(10000);

        return this.$q.when(promise);
    }

    countryCodes() {
        return this.Database.from('inspectors').list(10000).then((results) => {
            const countryCodes = results.map((result) => {
                return result.country.code;
            });

            // Create a Set to eliminate duplicates and then use the spread operator to convert it
            // back to an Array.
            return [...new Set(countryCodes)];
        });
    }


    countries() {
        return this.Database.from('inspectors').list(10000).then((results) => {
            const countries = results.map((result) => {
                return {
                    title: result.country.name,
                    isoCode: result.country.code,
                };
            });

            return uniqueCountries(countries);
        });
    }
    /**
     * Clear all records from this store and replace with the items that have been passed in
     *
     * @param  {Array} records Array of objects to store
     * @return {Promise}
     */
    replaceRecords(records) {
        const promise = this.Database.clear('inspectors').then(() => {
            return this.Database.putAll('inspectors', records);
        });

        return this.$q.when(promise);
    }
}
