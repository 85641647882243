/**
 * Drop in replacement for the simple usecases of the $ionicModal
 */

import rareModalDirective from './directive';
import rareModalService from './service';

angular.module('rare-modal', [])
    .directive('rareModal', rareModalDirective)
    .service('RareModal', rareModalService)
;
