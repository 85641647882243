export default class
{
    // Each 24-h from midnight may be divided into no more than two periods
    // At least 6-h continuous rest in a 24-h period
    // No more than 14-h between rests
    // At least 10-h rest in any 24-h
    // At least 77-h rest in any 7-days

    constructor(roster, hours) {
        this.roster = roster;
        this.hours = hours;

        this.rosterStart = moment(this.roster.startDate);
        this.rosterEnd = moment(this.roster.endDate);

        this.restHours = this.getRestHours();

        this.clearErrors();
    }

    validate() {
        let validates = true;

        // 1. in a 24-h period the hours of rest may be divided into no more than two periods;
        // TODO

        // 2. in a 24-h period one of the hours-of-rest period shall be at least six hours in
        //    length;
        validates &= this.check6HoursContinuousRestIn24Hours();

        // 3. the interval between consecutive periods of rest shall not exceed 14 hours;
        validates &= this.checkNoMoreThan14HoursBetweenRests();

        // 4. there should be a minimum 10 hours rest in any 24-h period
        validates &= this.check10HoursRestIn24HourPeriod();

        // 5. a minimum 77 hours in any 7 days.
        validates &= this.check77HoursRestIn7DayPeriod();

        return validates ? true : false;
    }

    clearErrors() {
        this.errors = [];
    }

    getLongestConsecutiveRestInPeriod(startDate, endDate) {
        let longestConsecutiveRest = 0;

        /**
         *      |   ---   |     a) inside
         *      |         |
         *     ---        |     b) partially inside
         *      |         |
         *      |        ---    c) partially inside
         *      |         |
         *     -------------    d) partially inside
         */

        this.restHours.forEach((rest) => {
            const insidePeriod = rest.startDate.isSameOrAfter(startDate) && rest.endDate.isSameOrBefore(endDate);

            // a)
            if (insidePeriod) {
                longestConsecutiveRest = Math.max(longestConsecutiveRest, rest.duration);
                return;
            }

            const partiallyInsidePeriod =
                // (b or d)
                rest.startDate.isBefore(startDate) && rest.endDate.isAfter(startDate) ||
                // (c or d)
                rest.startDate.isBefore(endDate) && rest.endDate.isAfter(endDate);

            if (partiallyInsidePeriod) {
                const partialStartDate = moment.max(rest.startDate, moment(startDate));
                const partialEndDate = moment.min(rest.endDate, moment(endDate));

                const partialRestDuration = moment.duration(
                    partialEndDate.diff(partialStartDate)
                ).asHours();

                longestConsecutiveRest = Math.max(longestConsecutiveRest, partialRestDuration);
            }
        });

        return longestConsecutiveRest;
    }

    /**
     * Invert the work hours to return a list of objects that describe the rest hours in the roster
     *
     * [
     *     {
     *         startDate: moment(),
     *         endDate: moment(),
     *         duration: 123,
     *     }
     * ]
     *
     * @return {[type]} [description]
     */
    getRestHours() {
        let restStart = this.rosterStart;

        const restHours = [];

        // console.log(this.hours);

        this.hours.forEach((entry) => {
            const entryStart = moment(entry.startDate);
            const entryEnd = moment(entry.endDate);

            if (entryStart.isAfter(restStart)) {
                restHours.push({
                    startDate: restStart,
                    endDate: entryStart,
                    duration: moment.duration(entryStart.diff(restStart)).asHours(),
                });
            }

            // Make our first known rest point start at the end of this work entry
            restStart = entryEnd;
        });

        // We need to now see if there is any time left before the end of the roster that should be
        // counted as rest after the last work record
        const lastWork = this.hours[this.hours.length - 1];

        if (lastWork && this.rosterEnd.isAfter(lastWork.endDate)) {
            restHours.push({
                startDate: moment(lastWork.endDate),
                endDate: this.rosterEnd,
                duration: moment.duration(moment(this.rosterEnd).diff(lastWork.endDate)).asHours(),
            });
        }

        return restHours;
    }

    /**
     * Get a list of event data about start and end times for hours of rest. Also produce a second
     * list of all event data less the hoursOffset.
     *
     * The data structure produced tracks the cumulative hours of rest up to each of the time
     * events. This is used by the moving window checks to see if the require rest in a period of
     * time have been achieved.
     *
     * @param  {Integer} hoursOffset
     * @return {Object}
     */
    getTimeWindowData(hoursOffset) {
        // Build event date for each start and end of each rest period
        const events = [];

        let cumulativeHours = 0;

        this.restHours.forEach((rest) => {
            const startOffset = moment.duration(rest.startDate.diff(this.rosterStart)).asHours();

            events.push({
                offset: startOffset,
                cumulativeHours,
            });

            const endOffset = moment.duration(rest.endDate.diff(this.rosterStart)).asHours();
            cumulativeHours += rest.duration;

            events.push({
                offset: endOffset,
                cumulativeHours,
            });
        });

        // Look through events data to build the offset data structure
        const eventsOffset = [];

        this.restHours.forEach((rest) => {
            const startOffset = moment.duration(rest.startDate.diff(this.rosterStart)).asHours() - hoursOffset;
            const endOffset = moment.duration(rest.endDate.diff(this.rosterStart)).asHours() - hoursOffset;

            // Work out how much cumulative rest has occured at each offset time
            [startOffset, endOffset].forEach((timeOffset) => {
                const offsetEvent = {
                    offset: timeOffset,
                    cumulativeHours: 0,
                };

                if (timeOffset > 0) {
                    let foundMatch = false;

                    // This is not so simple, we now need to iterate through the list of event data
                    // and see where this time falls. We can then work out the cummulative hours of
                    // rest at this place in time.
                    for (let i = 0; i < events.length && !foundMatch; i++) {
                        const event = events[i];

                        // Is this event after the offset event we're creating?
                        if (event.offset > offsetEvent.offset) {
                            // If the index of the event we've found is even then it is the start
                            // of a rest period, otherwise it is the end. If it's the start then we
                            // only need to look at the previous value. If it's the end we need to
                            // also consider how much of this rest period to add on
                            const previousEvent = events[i - 1];

                            if (previousEvent) {
                                // console.log('previousEvent', previousEvent.offset, offsetEvent.offset, previousEvent.cumulativeHours)
                                offsetEvent.cumulativeHours = previousEvent.cumulativeHours;

                                if (i % 2 === 1) {
                                    // Odd, so end of rest period - need to add on a bit more time
                                    const differenceInOffset = offsetEvent.offset - previousEvent.offset;
                                    offsetEvent.cumulativeHours += differenceInOffset;
                                }
                            }

                            foundMatch = true;
                        }
                    }
                }

                eventsOffset.push(offsetEvent);
            });
        });

        return {
            events,
            eventsOffset,
        };
    }

    /**
     * Checks for if the required hours of rest have been achieved in the moving hours window.
     *
     * @param  {Integer} hoursRest
     * @param  {Integer} hoursPeriod
     * @param  {String} validationError The string to add to the error object if validation fails
     * @return {Boolean}
     */
    checkHoursRestInHoursPeriod(hoursRest, hoursPeriod, validationError) {
        const timeWindow = this.getTimeWindowData(hoursPeriod);

        let validates = true;

        for (let i = 0; i < timeWindow.events.length; i++) {
            const event = timeWindow.events[i];
            const offsetEvent = timeWindow.eventsOffset[i];
            if (offsetEvent.offset >= 0) {
                const timeDifference = event.cumulativeHours - offsetEvent.cumulativeHours;
                if (timeDifference < hoursRest) {
                    validates = false;

                    this.errors.push({
                        type: validationError,
                        time: moment(this.rosterStart).add(event.offset, 'hours'),
                    });
                }
            }
        }

        return validates;
    }

    check10HoursRestIn24HourPeriod() {
        return this.checkHoursRestInHoursPeriod(10, 24, 'check10HoursRestIn24HourPeriod');
    }

    check77HoursRestIn7DayPeriod() {
        return this.checkHoursRestInHoursPeriod(77, 24 * 7, 'check77HoursRestIn7DayPeriod');
    }

    checkNoMoreThan14HoursBetweenRests() {
        // Make sure that the gap between the start of each rest period is not more than 14hrs from
        // the end of the previous one
        let lastRestEnd = null;
        let validates = true;

        this.restHours.forEach((rest) => {
            if (lastRestEnd) {
                const hoursSinceLastRest = moment.duration(rest.startDate.diff(lastRestEnd)).asHours();

                if (hoursSinceLastRest > 14) {
                    validates = false;

                    this.errors.push({
                        type: 'checkNoMoreThan14HoursBetweenRests',
                        time: rest.startDate,
                    });
                }
            }

            lastRestEnd = rest.endDate;
        });

        return validates;
    }

    check6HoursContinuousRestIn24Hours() {
        const timeWindow = this.getTimeWindowData(24);
        const rosterDuration = moment.duration(this.rosterEnd.diff(this.rosterStart)).asHours();

        // If the roster period is less than 24 hours then we can't fail this
        if (rosterDuration < 24) {
            return true;
        }

        // If there has been no rest, then we should immediately fail
        if (timeWindow.events.length === 0) {
            this.errors.push({
                type: 'check6HoursContinuousRestIn24Hours',
                time: this.rosterEnd,
            });

            return false;
        }

        // If the first offset time is more than 18 then we know we can't have had 6 hours
        // consecutive rest in the first 24 hours
        if (timeWindow.events[0].offset > 18) {
            this.errors.push({
                type: 'check6HoursContinuousRestIn24Hours',
                time: moment(this.rosterStart).add(18, 'hours'),
            });

            return false;
        }

        // Now we check all the event times to see if in the previous 24 hours at least 6 hours
        // consecutive rest occured
        let validates = true;

        for (let i = 0; i < timeWindow.events.length; i++) {
            const event = timeWindow.events[i];

            if (event.offset >= 24) {
                const endDate = moment(this.rosterStart).add(event.offset, 'hours');
                const startDate = moment(endDate).subtract(24, 'hours');
                const longestConsecutiveRest = this.getLongestConsecutiveRestInPeriod(startDate, endDate);

                if (longestConsecutiveRest < 6) {
                    validates = false;

                    this.errors.push({
                        type: 'check6HoursContinuousRestIn24Hours',
                        time: moment(this.rosterStart).add(event.offset, 'hours'),
                    });
                }
            }
        }

        return validates;
    }
}
