export default class
{
    constructor(
        $http,
        AppConfig,
        AffiliateUnionTransformer,
        InspectorTransformer,
        VesselTransformer,
        LookupTransformer,
        PortTransformer,
        SyncStatusTransformer,
        OfficeTransformer,
        InspectionTransformer,
        HelpRequestTransformer,
        Auth,
        $q
    ) {
        this.$http = $http;
        this.$q = $q;
        this.AppConfig = AppConfig;
        this.Auth = Auth;

        this.AffiliateUnionTransformer = AffiliateUnionTransformer;
        this.InspectorTransformer = InspectorTransformer;
        this.VesselTransformer = VesselTransformer;
        this.LookupTransformer = LookupTransformer;
        this.PortTransformer = PortTransformer;
        this.OfficeTransformer = OfficeTransformer;
        this.SyncStatusTransformer = SyncStatusTransformer;
        this.InspectionTransformer = InspectionTransformer;
        this.HelpRequestTransformer = HelpRequestTransformer;
    }

    getSyncStatus(lastChecked = null) {
        const lastCheckedDate = moment(lastChecked || '2000-01-01');
        const urlSafeParam = encodeURIComponent(lastCheckedDate.format());

        const url = `synchstatus?lastchecked=${lastCheckedDate.format('YYYY-MM-DDTHH:mm:ss')}`;
        // const url = `synchstatus?lastchecked=${urlSafeParam}`;

        return this.getRequest(url, (input) => {
            return this.SyncStatusTransformer.inbound(input[0]);
        });
    }

    getAffiliateUnions() {
        return this.getRequest('affiliates', (input) => {
            return input.map((union) => {
                return this.AffiliateUnionTransformer.inbound(union);
            });
        });
    }

    getAffiliateUnion(id) {
        return this.getRequest(`affiliates/${id}`, this.AffiliateUnionTransformer.inbound);
    }

    getInspectors() {
        return this.getRequest('inspectors', (input) => {
            const types = {};

            return input.map((union) => {
                return this.InspectorTransformer.inbound(union);
            });
        });
    }

    getInspector(id) {
        return this.getRequest(`inspectors/${id}`, this.InspectorTransformer.inbound);
    }

    getVessels(searchString, searchType='name') {
        const keys = {
            name: 'VesselName',
            imo: 'IMONumber',
        };

        const searchKey = keys[searchType] || keys.name;

        // TODO: Distinguish between IMO and text search
        return this.getRequest(`vessels/?${searchKey}=${searchString}`, (input) => {
            if (!Array.isArray(input)) {
                return [];
            }
            return input.map((vessel) => {
                return this.VesselTransformer.inbound(vessel);
            });
        });
    }

    getVesselWithIMO(imo) {
        return this.getRequest(`vessels/?IMONumber=${imo}`, (input) => {
            return this.VesselTransformer.inbound(input[0]);
        });
    }

    getLookups() {
        return this.getRequest('lookups', (input) => {
            return input.map((lookup) => {
                return this.LookupTransformer.inbound(lookup);
            });
        });
    }

    getPorts() {
        return this.getRequest('ports', (input) => {
            return input.map((port) => {
                return this.PortTransformer.inbound(port);
            });
        });
    }

    getOffices() {
        return this.getRequest('itfoffices', (input) => {
            return input.map((office) => {
                return this.OfficeTransformer.inbound(office);
            });
        });
    }

    postInspection(data) {
        return this.postRequest(
            'inspections',
            this.InspectionTransformer.outbound(data),
            null,
            true
        );
    }

    postHelpRequest(data) {
        return this.postRequest('helprequests', this.HelpRequestTransformer.outbound(data));
    }

    getRequest(path, transformer = null, requiresAuth = false) {
        return this.makeRequest(path, 'GET', null, transformer, requiresAuth);
    }

    postRequest(path, data, transformer = null, requiresAuth = false) {
        return this.makeRequest(path, 'POST', data, transformer, requiresAuth);
    }

    makeRequest(path, method, data = null, transformer = null, requiresAuth = false) {
        const httpOptions = {
            method,
            data,
            headers: {},
            url: `${this.AppConfig.api.url}/${path}`,
        };

        return this.$q.resolve().then(() => {
            if (!requiresAuth) {
                return this.$q.resolve();
            }

            return this.Auth.refreshToken().then((authResponse) => {
                httpOptions.headers.Authorization = authResponse.createAuthorizationHeader();
            }).catch(() => {
                // Silent refresh failed
                httpOptions.headers.Authorization = '';
            });
        }).then(() => {
            // If we have a transformer supplied, make sure it runs after the defaults
            if (transformer) {
                let defaults = this.$http.defaults.transformResponse;

                // We can't guarantee that the default transformation is an array
                defaults = angular.isArray(defaults) ? defaults : [defaults];

                httpOptions.transformResponse = defaults.concat(transformer);
            }

            return this.$http(httpOptions);
        });
    }
}
