export default () => {
    /* eslint-disable no-dupe-keys */

    return {
        twitter: 'itfglobalunion',
        facebook: 'itfseafarerssupport',

        helpline: {
            phone: '+442079409280',
            sms: '+447984356573',
            whatsApp: '+447523515097',
        },

        debug: false,
        urlEncode: true,
        api: {
            url: '/proxy.php?csurl=https://itfapi20170206042825.azurewebsites.net/api',
            auth: {
                authority: 'https://login.windows.net/common',
                redirectUri: 'http://itfcordovaapp',
                // resourceUri: 'https://graph.windows.net',
                resourceUri: 'https://itfapi20170206042825.azurewebsites.net',
                clientId: 'ec8dd4c6-5474-4ebf-abaf-a7ff8e165ef9',
            },
        },
        marineTraffic: {
            baseUrl: '/proxy.php?csurl=https://www.marinetraffic.com',
            apiKey: 'MTAPIKEY',
        },
    };

    /* eslint-enable no-dupe-keys */
};
