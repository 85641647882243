import FormHelper from '../helpers/FormHelper';

export default class
{
    constructor(API, $state, $cordovaDialogs, $scope) {
        this.API = API;
        this.$state = $state;
        this.$cordovaDialogs = $cordovaDialogs;

        this.message = {};

        window.addEventListener('native.keyboardshow', (event) => {
            this.keyboardPadding = event.keyboardHeight;
            $scope.$apply();
        });

        window.addEventListener('native.keyboardhide', (event) => {
            this.keyboardPadding = 0;
            $scope.$apply();
        });
    }

    submitForm(form) {
        FormHelper.setAllErrorFieldsAsDirty(form);

        if (!form.$valid) {
            // alert('Please fix issues with form. This order has NOT been saved.');
            FormHelper.scrollToFirstError();
            return Promise.resolve(false);
        }

        this.loading = true;

        const payload = angular.extend({
            method: 'ITF Seafarers App',
        }, this.message);

        return this.API.postHelpRequest(payload).catch((error) => {
            console.error(error);
            this.$cordovaDialogs.alert(
                'Your message could not be sent at this time. Either you do not have a network connection or the service is temporarily unavailable.',
                'Unable to send message'
            );
        }).then(() => {
            return this.$cordovaDialogs.alert(
                'Your message has been sent, someone will be in contact as soon as possible.',
                'Message sent'
            ).then(() => {
                this.$state.go('home');
            });
        }).finally(() => {
            this.loading = false;
        });
    }
}
