/**
 * Makes a textarea grow and shrink to the size of its contents
 */
export default () => {
    return {
        restrict: 'A',

        link(scope, element, attrs) {
            const update = () => {
                element.css('height', 'auto');

                const height = element[0].scrollHeight;

                if (height > 0) {
                    element.css('height', `${height}px`);
                }
            };

            scope.$watch(attrs.ngModel, update);
            attrs.$set('ngTrim', 'false');
        },
    };
};
