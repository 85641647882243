/**
 * Show native date picker
 */
export default ($cordovaDatePicker, $timeout) => {
    return {
        restrict: 'E',

        scope: {
            date: '=',
            update: '&onUpdate',
        },

        template: `
            <div ng-if="vm.cordovaEnabled">
                <span class="itf-date-picker__date" ng-click="vm.showDate()">{{ date|date:'yyyy-MM-dd' }}</span>
                <span class="itf-date-picker__time" ng-click="vm.showTime()">{{ date|date:'HH:mm' }}</span>
            </div>
            <div ng-if="!vm.cordovaEnabled">
                <span moment-picker="vm.fallbackDate" max-view="minute" format="YYYY-MM-DD HH:mm">{{ vm.fallbackDate }}</span>
            </div>
        `,

        controllerAs: 'vm',

        controller($element, $scope) {
            this.cordovaEnabled = 'cordova' in window;

            // If we don't have cordova we need to do some juggling to let us update from a text
            // input
            this.fallbackDate = moment($scope.date).toDate();

            console.log('fallbackDate', this.fallbackDate);

            const options = {
                mode: 'date', // or 'time'
                // minDate: new Date() - 10000,
                allowOldDates: true,
                allowFutureDates: true,
                // doneButtonLabel: 'DONE',
                // doneButtonColor: '#F2F3F4',
                // cancelButtonLabel: 'CANCEL',
                cancelButtonColor: '#FF6363',
            };

            this.updateFromFallback = () => {
                $scope.date = this.fallbackDate;
                console.log('fallbackDate', this.fallbackDate);
                console.log('$scope.date', $scope.date);

                this.update();
            };

            this.update = () => {
                if ($scope.update) {
                    $timeout(() => {
                        $scope.update();
                    }, 20);
                }
            };

            this.showDate = () => {
                $cordovaDatePicker.show(angular.extend({}, options, {
                    date: $scope.date || new Date(),
                    mode: 'date',
                })).then((date) => {
                    const selectedDate = moment(date);
                    const modelDate = moment($scope.date);

                    // Mutate the model date to match what was selected
                    modelDate.set('date', selectedDate.get('date'));
                    modelDate.set('month', selectedDate.get('month'));
                    modelDate.set('year', selectedDate.get('year'));

                    $scope.date = modelDate.toDate();
                    this.update();
                });
            };

            this.showTime = () => {
                $cordovaDatePicker.show(angular.extend({}, options, {
                    date: $scope.date,
                    mode: 'time',
                })).then((date) => {
                    const selectedDate = moment(date);
                    const modelDate = moment($scope.date);

                    // Mutate the model date to match what was selected
                    modelDate.set('hour', selectedDate.get('hour'));
                    modelDate.set('minute', selectedDate.get('minute'));
                    modelDate.set('second', 0);

                    $scope.date = modelDate.toDate();
                    this.update();
                });
            };
        },
    };
};
