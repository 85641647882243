export default () => {
    return {
        restrict: 'A',

        // Call this function when the directive is rendered
        link: (scope, elem, attr) => {
            const cleanUrl = (value) => {
                // iOS falls over if it has spaces in the number
                if (value.startsWith('tel:')) {
                    const parts = value.split(':');

                    // Remove all non numeric plus, or space characters
                    let cleanedNumber = parts[1].replace(/[^0-9\+\s]/g, '');

                    // Replace space characters with `-`
                    cleanedNumber = cleanedNumber.replace(/\s/g, '-');

                    value = `tel:${cleanedNumber}`;
                }

                if (value.startsWith('sms:')) {
                    const parts = value.split(':');

                    // Remove all non numeric plus including space characters
                    const cleanedNumber = parts[1].replace(/[^0-9\+]/g, '');

                    value = `sms:${cleanedNumber}`;
                }

                return value;
            };

            let url = attr.rareHref;
            console.log(`RareHref directive: ${url}`);

            if (url) {
                elem[0].addEventListener('click', (event) => {

                    if (!('cordova' in window) || !('InAppBrowser' in cordova)) {
                        return true;
                    }

                    event.preventDefault();

                    let target = attr.target;

                    // If the URL is a special protocol that opens in a new app, Chrome on
                    // Android will throw an error if it is not opened in the System browser.
                    //
                    // `Failed to load resource: net::ERR_UNKNOWN_URL_SCHEME`
                    ['mailto:', 'sms:', 'tel:'].forEach((protocol) => {
                        if (url.startsWith(protocol)) {
                            target = '_system';
                        }
                    });

                    url = cleanUrl(url);

                    // console.log(`InAppBrowser.open(${url}, ${target})`);
                    scope.$emit('rareHref', {
                        url,
                        target,
                    });

                    cordova.InAppBrowser.open(url, target);
                }, true);
            }
        },
    };
};
