/**
 * Autofocuses an input when the route shows
 */
export default ($timeout) => {
    return {
        restrict: 'A',

        controller($element) {
            $timeout(() => {
                $element[0].focus();
            }, 150);
        },
    };
};
