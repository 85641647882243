import debounce from '../helpers/debounce';

export default class
{
    constructor(InspectionReport) {
        this.inspection = InspectionReport.data();
        this.notes = this.inspection.notes;

        this.debounceUpdateNotes = debounce(() => {
            this.inspection.notes = this.notes;
        }, 1000);
    }
}
