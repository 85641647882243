export default class
{
    constructor(AppConfig, inspections) {
        this.inspections = inspections;
        this.helpline = AppConfig.helpline;
    }

    selectWhatsAppNumber() {
        const copyNumberToClipboard = () => {
            if ('cordova' in window && 'plugins' in cordova && 'clipboard' in cordova.plugins) {
                cordova.plugins.clipboard.copy(this.helpline.whatsApp);
            }
        };

        if ('plugins' in window && 'actionsheet' in plugins) {
            const options = {
                buttonLabels: ['Copy number'],
                androidEnableCancelButton: true, // default false
                winphoneEnableCancelButton: true, // default false
                addCancelButtonWithLabel: 'Cancel',
            };

            window.plugins.actionsheet.show(options, (index) => {
                if (index === 1) {
                    copyNumberToClipboard();
                }
            });
        } else {
            console.warn('Can not copy to clipboard outside of Cordova');
        }
    }
}
