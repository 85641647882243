const schema = {
    stores: [
        {
            name: 'inspectors',
            keyPath: 'id',
            autoIncrement: false,
            indexes: [
                {
                    keyPath: 'country.code',
                },
            ],
        },

        {
            name: 'unions',
            keyPath: 'id',
            autoIncrement: false,
            indexes: [
                {
                    keyPath: 'country.code',
                },
            ],
        },

        {
            name: 'vessels',
            keyPath: 'id',
            autoIncrement: false,
            indexes: [
                {
                    keyPath: 'imo',
                },
            ],
        },

        {
            name: 'lookups',
            keyPath: 'id',
            autoIncrement: false,
            indexes: [
                {
                    keyPath: 'attributeName',
                },
            ],
        },

        {
            name: 'ports',
            keyPath: 'id',
            autoIncrement: false,
            indexes: [
                {
                    keyPath: 'country.code',
                },
            ],
        },

        {
            name: 'offices',
            keyPath: 'id',
            autoIncrement: false,
        },

        {
            name: 'inspections',
            keyPath: 'id',
            autoIncrement: true,
        },

        {
            name: 'rosters',
            keyPath: 'id',
            autoIncrement: true,
        },

        {
            name: 'roster-hours',
            keyPath: 'id',
            autoIncrement: true,
            indexes: [
                {
                    keyPath: 'rosterId',
                },
            ],
        },
    ],
};

export default () => {
    return new ydn.db.Storage('itf-seafarers', schema);
};
