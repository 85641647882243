export default () => {
    return {
        outbound(input) {
            return {
                Dated: input.date || moment().format(),
                Name: input.name || '',
                Ranking: input.rank || '',
                Nationality: input.nationality || '',
                Phone: input.phone || '',
                Email: input.email || '',
                ShipName: input.shipName || '',
                Flag: input.flag || '',
                IMONumber: input.imo || '',
                ShipType: input.shipType || '',
                ProblemDescription: input.problem || '',
                CurrentPort: input.currentPort || '',
                SailDate: input.sailDate ? moment(input.sailDate).format('YYYY-MM-DD') : '',
                NextPort: input.nextPort || '',
                ETA: input.eta || '',
                ContactMethod: input.method || '',
            };
        },
    };
};
