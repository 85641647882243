import uniqueCountries from '../helpers/uniqueCountries';

export default {
    'inspectors-and-unions-search': {
        parent: 'app',
        url: '/inspectors-and-unions/search',
        views: {
            app: {
                controller: 'InspectorsAndUnionsSearchController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/search.html',
            },
        },
        resolve: {
            countries: (Inspectors, AffiliateUnions) => {
                const promises = [
                    Inspectors.countries(),
                    AffiliateUnions.countries(),
                ];

                return Promise.all(promises).then(([countries1, countries2]) => {
                    const joinedCountries = countries1.concat(countries2);

                    return uniqueCountries(joinedCountries);
                });
            },
        },
    },

    'inspectors-and-unions-show': {
        parent: 'app',
        abstract: true,
        url: '/inspectors-and-unions/show/:country',
        views: {
            app: {
                controller: 'InspectorsAndUnionsShowController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/show.html',
            },
        },
        resolve: {
            inspectors: ($stateParams, Inspectors) => {
                return Inspectors.allForCountryCode($stateParams.country);
            },
            unions: ($stateParams, AffiliateUnions) => {
                return AffiliateUnions.allForCountryCode($stateParams.country);
            },
            countryTitle: ($stateParams, inspectors, unions, countryName) => {
                if (inspectors.length > 0) {
                    return inspectors[0].country.name;
                }

                if (unions.length > 0) {
                    return unions[0].country.name;
                }

                return countryName($stateParams.country);
            },
        },
    },

    'inspectors-and-unions-show.inspectors': {
        url: '/inspectors',
        views: {
            'inspectors-and-unions': {
                controller: 'InspectorsAndUnionsShowInspectorsController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/show.inspectors.html',
            },
        },
    },

    'inspectors-and-unions-show.unions': {
        url: '/unions',
        views: {
            'inspectors-and-unions': {
                controller: 'InspectorsAndUnionsShowUnionsController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/show.unions.html',
            },
        },
    },

    inspector: {
        parent: 'app',
        url: '/inspectors/:id',
        views: {
            app: {
                controller: 'InspectorController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/show.inspector.html',
            },
        },
        resolve: {
            inspector: ($stateParams, Inspectors) => {
                return Inspectors.fetch($stateParams.id);
            },
        },
    },

    union: {
        parent: 'app',
        url: '/unions/:id',
        views: {
            app: {
                controller: 'UnionController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/show.union.html',
            },
        },
        params: {
            union: null,
        },
        resolve: {
            union: ($stateParams, AffiliateUnions) => {
                return AffiliateUnions.fetch($stateParams.id).then((union) => {
                    if (union) {
                        return union;
                    }

                    // See if we have passed the data in. This is because not all unions affilited
                    // with a vessel are ITF unions so might not be in the database
                    return $stateParams.union || false;
                });
            },
        },
    },

    'union-map': {
        parent: 'app',
        url: '/unions/:id/map',
        views: {
            app: {
                controller: 'UnionMapController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspectors-and-unions/map.union.html',
            },
        },
        params: {
            union: null,
        },
        resolve: {
            union: ($stateParams, AffiliateUnions) => {
                return AffiliateUnions.fetch($stateParams.id).then((union) => {
                    if (union) {
                        return union;
                    }

                    // See if we have passed the data in. This is because not all unions affilited
                    // with a vessel are ITF unions so might not be in the database
                    return $stateParams.union || false;
                });
            },
        },
    },
};
