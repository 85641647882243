import debounce from '../../helpers/debounce';

/**
 * Applies an 'activated' class when maching elements are interacted with
 */
export default () => {
    return {
        restrict: 'C',

        controller($element, $scope) {
            $element.on('keyup', debounce(() => {
                $scope.$apply(() => {
                    $scope.$broadcast('searchbox:update', $element.val());
                });
            }, 500));
        },
    };
};
