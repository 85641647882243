export default {
    rosters: {
        parent: 'app',
        url: '/rosters',
        views: {
            app: {
                controller: 'RostersController',
                controllerAs: 'vm',
                templateUrl: 'templates/rosters/index.html',
            },
        },
        resolve: {
            rosters: (Rosters) => {
                // Rosters.store({
                //     startDate: moment().format(),
                //     endDate: moment().format(),
                // });
                return Rosters.all();
            },
        },
    },

    'roster-abstract': {
        parent: 'app',
        abstract: true,
        url: '/rosters/:id',
        views: {
            app: {
                controller: 'RosterShowController',
                controllerAs: 'vm',
                templateUrl: 'templates/rosters/roster.html',
            },
        },
        resolve: {
            roster: (Rosters, $stateParams) => {
                return Rosters.fetch(parseInt($stateParams.id, 10));
            },
        },
    },

    'roster-details': {
        parent: 'roster-abstract',
        url: '/details',
        views: {
            'tab-contents': {
                controller: 'RosterDetailsController',
                controllerAs: 'vm',
                templateUrl: 'templates/rosters/roster.details.html',
            },
        },
    },

    'roster-hours': {
        parent: 'roster-abstract',
        url: '/hours',
        views: {
            'tab-contents': {
                controller: 'RosterHoursController',
                controllerAs: 'vm',
                templateUrl: 'templates/rosters/roster.hours.html',
            },
        },
        resolve: {
            hours: ($stateParams, Rosters) => {
                return Rosters.allHoursForRoster($stateParams.id);
            },
        },
    },
};
