import API from './services/API';

import AffiliateUnionTransformer from './transformers/AffiliateUnion';
import InspectorTransformer from './transformers/Inspector';
import VesselTransformer from './transformers/Vessel';
import LookupTransformer from './transformers/Lookup';
import PortTransformer from './transformers/Port';
import SyncStatusTransformer from './transformers/SyncStatus';
import OfficeTransformer from './transformers/Office';
import InspectionTransformer from './transformers/Inspection';
import HelpRequestTransformer from './transformers/HelpRequest';

angular.module('itf.crm-api', [])
    .service('API', API)
    .service('AffiliateUnionTransformer', AffiliateUnionTransformer)
    .service('InspectorTransformer', InspectorTransformer)
    .service('VesselTransformer', VesselTransformer)
    .service('LookupTransformer', LookupTransformer)
    .service('PortTransformer', PortTransformer)
    .service('SyncStatusTransformer', SyncStatusTransformer)
    .service('OfficeTransformer', OfficeTransformer)
    .service('InspectionTransformer', InspectionTransformer)
    .service('HelpRequestTransformer', HelpRequestTransformer)
;
