/**
 * StackView
 *
 * TODO: Document the following state params:
 *
 *  - stackview.ignoreParams
 *  - replaceInStackView
 */
export default ($compile, $state, $interpolate) => {
    return {
        restrict: 'E',

        transclude: true,

        controller($scope) {
            this.states = [];

            this.indexOfState = (state, params) => {
                const index = this.states.findIndex((item) => {
                    const itemParamsCopy = angular.copy(item.params);
                    const paramsCopy = angular.copy(params);

                    if (state.stackview && state.stackview.ignoreParams) {
                        state.stackview.ignoreParams.forEach((key) => {
                            delete itemParamsCopy[key];
                            delete paramsCopy[key];
                        });
                    }

                    return item.name === state.name && angular.equals(itemParamsCopy, paramsCopy);
                });

                return index;
            };

            this.goBack = (params = {}) => {
                const previousState = this.states[this.states.length - 2];

                $state.go(previousState.name, angular.extend({}, previousState.params, params));
            };

            $scope.$on('stackView:goBack', () => {
                this.goBack();
            });
        },

        link(scope, element, attr, ctrl, transclude) {
            let currentState = {};

            transclude(scope, () => {
                const content = angular.element(`
                    <div ui-view="${attr.name}"></div>
                `);

                element.append(content);
                $compile(content)(scope);
            });

            scope.$on('$stateChangeSuccess', (event, toState, toParams) => {
                // Get the element and scope for the ui-view element
                const uiViewElement = element.children().eq(0);
                const uiViewScope = uiViewElement.scope();

                // Get the inherited ui-view data
                const inherited = uiViewElement.inheritedData('$uiView');

                // If there is no data then we can't do anything so bail early
                if (!inherited) {
                    return;
                }

                // Get the name of the ui-view element
                const uiViewName = uiViewElement.attr('ui-view') || uiViewElement.attr('name');
                const name = $interpolate(uiViewName || '')(uiViewScope);

                // Get a list of all the views that could be affected by this route or its parents.
                // This lets us determine whether we need to consider this state as something we
                // care about
                const allLocals = {};

                const processRoute = (routeName) => {
                    const routeConfig = $state.get(routeName);

                    if (routeConfig) {
                        const routeLocals = routeConfig.$$state().locals;
                        for (const key of Object.keys(routeLocals || {})) {
                            allLocals[key] = routeLocals[key];
                        }
                    }
                };

                if ('$$state' in toState) {
                    Object.keys(toState.$$state().includes).forEach(processRoute);
                } else {
                    allLocals[inherited.name] = toState;
                }

                if (!allLocals[inherited.name]) {
                    return;
                }

                // Create a way to check the current state against the last one that effected this
                // view
                const state = {
                    name: inherited.state.name,
                    locals: inherited.state.locals[`${name}@`],
                };

                const stateForStack = {
                    name: $state.$current.name,
                    params: toParams,
                };

                scope.$emit('stackViewPush', stateForStack);

                let replaceInStackView = false;

                if (state.locals && state.locals.$stateParams) {
                    replaceInStackView = (state.locals.$stateParams.replaceInStackView && !state.locals.$stateParams.ignoreReplaceInStackView);
                }

                // If this state is diff from the last then we have done something to change this
                // view and we should push this state onto the stack
                if (
                    currentState.name === state.name &&
                    (currentState.locals === state.locals || replaceInStackView)
                ) {
                    // Replace the top item in our stack with the current state
                    const lastIndex = ctrl.states.length - 1;
                    ctrl.states.splice(lastIndex, 1, stateForStack);

                    return;
                }

                currentState = state;

                ctrl.states.push(stateForStack);
            });

            scope.$on('$stateChangeStart', (event, toState, toParams) => {
                // If someone else has prevented this event from completing, we know that the
                // state will not change so we don't need to do any of this processing
                if (event.defaultPrevented) {
                    return;
                }

                const index = ctrl.indexOfState(toState, toParams);

                // Prevent a transition effect on the first app load
                if (!ctrl.hasTransitioned || (toParams.replaceInStackView && !toParams.ignoreReplaceInStackView)) {
                    element.addClass('no-animation');
                    /* eslint-disable no-param-reassign */
                    ctrl.hasTransitioned = true;
                    /* eslint-enable no-param-reassign */
                } else {
                    element.removeClass('no-animation');
                }

                // If the index is greater that -1 then the state is in our previous stack. We also
                // want to make sure that the state isn't the top most item in the stack, as this
                // doesn't represent a change to our current view state.
                if (index >= 0 && index < ctrl.states.length - 1) {
                    element.addClass('back');

                    // Remove the items from the array;
                    const removedItems = ctrl.states.splice(index, ctrl.states.length - index);

                    scope.$emit('stackViewPop', removedItems[0]);
                } else {
                    element.removeClass('back');
                }
            });
        },
    };
};
