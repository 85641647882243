import Inspectors from './services/Inspectors';
import AffiliateUnions from './services/AffiliateUnions';
import Vessels from './services/Vessels';
import VesselsSearchCache from './services/VesselsSearchCache';
import Rosters from './services/Rosters';
import Lookups from './services/Lookups';
import Ports from './services/Ports';
import Offices from './services/Offices';
import RouteModal from './services/RouteModal';
import DataSync from './services/DataSync';
import InspectionReport from './services/InspectionReport';
import Inspections from './services/Inspections';
import InspectionsQueue from './services/InspectionsQueue';
import Auth from './services/Auth';
import MarineTraffic from './services/MarineTraffic';

angular.module('app.services', [])
    .service('Inspectors', Inspectors)
    .service('AffiliateUnions', AffiliateUnions)
    .service('Vessels', Vessels)
    .service('VesselsSearchCache', VesselsSearchCache)
    .service('Rosters', Rosters)
    .service('Lookups', Lookups)
    .service('Ports', Ports)
    .service('Offices', Offices)
    .service('RouteModal', RouteModal)
    .service('DataSync', DataSync)
    .service('InspectionReport', InspectionReport)
    .service('Inspections', Inspections)
    .service('InspectionsQueue', InspectionsQueue)
    .service('Auth', Auth)
    .service('MarineTraffic', MarineTraffic)
;
