export default {
    resources: {
        parent: 'app',
        url: '/resources',
        views: {
            app: {
                controller: 'ResourcesController',
                controllerAs: 'vm',
                templateUrl: 'templates/resources/index.html',
            },
        },
    },

    contact: {
        parent: 'app',
        url: '/contact',
        views: {
            app: {
                controller: 'ContactController',
                controllerAs: 'vm',
                templateUrl: 'templates/resources/contact.html'
            }
        }
    },

    'about-itf': {
        parent: 'app',
        url: '/resources/about-itf',
        views: {
            app: {
                templateUrl: 'templates/resources/about-itf.html',
            },
        },
    },

    timezones: {
        parent: 'app',
        url: '/resources/timezones',
        views: {
            app: {
                controller: 'TimezonesController',
                controllerAs: 'vm',
                templateUrl: 'templates/resources/timezones.html',
            },
        },
    },

    foc: {
        parent: 'app',
        url: '/resources/foc',
        views: {
            app: {
                templateUrl: 'templates/resources/foc.html',
            },
        },
    },

    'foc-impact': {
        parent: 'app',
        url: '/resources/foc-impact',
        views: {
            app: {
                templateUrl: 'templates/resources/foc-impact.html',
            },
        },
    },

    'foc-campaign-aims': {
        parent: 'app',
        url: '/resources/foc-campaign-aims',
        views: {
            app: {
                templateUrl: 'templates/resources/foc-campaign-aims.html',
            },
        },
    },

    'foc-inspectorate': {
        parent: 'app',
        url: '/resources/foc-inspectorate',
        views: {
            app: {
                templateUrl: 'templates/resources/foc-inspectorate.html',
            },
        },
    },

    'foc-registries': {
        parent: 'app',
        url: '/resources/foc-registries',
        views: {
            app: {
                templateUrl: 'templates/resources/foc-registries.html',
            },
        },
    },

    'foc-message': {
        parent: 'app',
        url: '/resources/foc-message',
        views: {
            app: {
                templateUrl: 'templates/resources/foc-message.html',
            },
        },
    },

    'working-hours-regulations': {
        parent: 'app',
        url: '/resources/working-hours-regulations',
        views: {
            app: {
                templateUrl: 'templates/resources/working-hours-regulations.html',
            },
        },
    },

    'phonetic-alphabet': {
        parent: 'app',
        url: '/resources/phonetic-alphabet',
        views: {
            app: {
                controller: function (phoneticAlphabet) {
                    this.alphabet = phoneticAlphabet;
                },
                controllerAs: 'vm',
                templateUrl: 'templates/resources/phonetic-alphabet.html',
            },
        },
    },

    'itf-worldwide': {
        parent: 'app',
        url: '/resources/itf-worldwide',
        views: {
            app: {
                controller: 'ITFOfficesController',
                controllerAs: 'vm',
                templateUrl: 'templates/resources/itf-worldwide.html',
            },
        },
        resolve: {
            offices: (Offices) => {
                return Offices.all();
            },
        },
    },

    'itf-office': {
        parent: 'app',
        url: '/resources/itf-office/:id',
        views: {
            app: {
                controller: 'ITFOfficeController',
                controllerAs: 'vm',
                templateUrl: 'templates/resources/itf-office.html',
            },
        },
        resolve: {
            office: (Offices, $stateParams) => {
                console.log($stateParams.id);
                return Offices.fetch($stateParams.id);
            },
        },
    },
};
