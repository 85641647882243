import RosterValidator from '../helpers/RosterValidator';

export default class
{
    constructor(roster, hours, Rosters, $scope) {
        this.Rosters = Rosters;
        this.roster = roster;
        this.hours = hours;

        this.months = [];

        this.sortHoursIntoMonths();

        $scope.$on('hoursChanged', (event) => {
            this.updateHours().then(() => {
                const validator = new RosterValidator(this.roster, this.hours);

                this.roster.valid = validator.validate();

                Rosters.store(this.roster);
            });
        });
    }

    sortHoursIntoMonths() {
        this.months = [];

        this.hours = this.hours.sort((hoursA, hoursB) => {
            return moment(hoursA.startDate).isBefore(hoursB.startDate) ? -1 : 1;
        });

        this.hours.forEach((hours) => {
            const key = moment(hours.startDate).format('MMM YY');

            let currentGroup = this.months[this.months.length - 1];

            if (currentGroup && currentGroup.title === key) {
                currentGroup.hours.push(hours);
            } else {
                currentGroup = {
                    title: key,
                    hours: [
                        hours,
                    ],
                };

                this.months.push(currentGroup);
            }
        });
    }

    updateHours() {
        console.log('Update hours from Database');

        return this.Rosters.allHoursForRoster(this.roster).then((hours) => {
            this.hours = hours;
            this.sortHoursIntoMonths();
        });
    }
}
