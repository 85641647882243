export default class
{
    constructor($stateParams, countryTitle, API, AffiliateUnions) {
        this.countryTitle = countryTitle;
        this.countryCode = $stateParams.country;

        // console.log(inspectors);

        // API.getAffiliateUnions().then((response) => {
        //     console.log('api response');
        //     return AffiliateUnions.replaceRecords(response.data);
        // }).then((retVal) => {
        //     console.log('replaceRecords', retVal);
        // }).catch((error) => {
        //     console.error(error);
        // });
    }
}
