export default class
{
    constructor(Database, $q) {
        this.$q = $q;
        this.Database = Database;
    }

    fetch(id) {
        return this.$q.when(this.Database.get('lookups', id));
    }

    all() {
        return this.$q.when(this.Database.from('lookups').list(10000));
    }

    allOfType(type) {
        return this.$q.when(this.Database.from('lookups').where('attributeName', '=', type).list(10000));
    }

    allInspectionReasons() {
        return this.allOfType('itf_caseorigin').then((results) => {
            return results.sort((a, b) => {
                return a.displayOrder < b.displayOrder ? -1 : 1;
            });
        });
    }

    allInspectionTypes() {
        return this.allOfType('itf_casetype').then((results) => {
            return results.sort((a, b) => {
                return a.displayOrder < b.displayOrder ? -1 : 1;
            });
        });
    }

    store(vessel) {
        return this.$q.when(this.Database.put('lookups', vessel));
    }

    /**
     * Clear all records from this store and replace with the items that have been passed in
     *
     * @param  {Array} records Array of objects to store
     * @return {Promise}
     */
    replaceRecords(records) {
        const promise = this.Database.clear('lookups').then(() => {
            return this.Database.putAll('lookups', records);
        });

        return this.$q.when(promise);
    }

    clear() {
        return this.Database.clear('lookups');
    }
}
