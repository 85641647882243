export default class
{
    constructor(offices) {
        const officesByCountry = {};

        offices.forEach((office) => {
            if (!officesByCountry[office.country.name]) {
                officesByCountry[office.country.name] = [];
            }

            officesByCountry[office.country.name].push(office);
        });

        this.countries = Object.keys(officesByCountry).map((country) => {
            return {
                name: country,
                offices: officesByCountry[country].sort((a, b) => {
                    return a.name.localeCompare(b.name);
                }),
            };
        }).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }
}
