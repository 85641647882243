/**
 * Sets up a modal view from a template and a given state. Used in a routes `onEnter` function
 */
export default class
{
    constructor(RareModal, $rootScope, $previousState, $state) {
        this.RareModal = RareModal;
        this.$rootScope = $rootScope;
        this.$previousState = $previousState;
        this.$state = $state;
    }

    setup(state, template) {
        if (state.modalOpen) {
            return;
        }

        /* eslint-disable no-param-reassign */
        state.modalOpen = true;

        // Remember the previous state with memoName "modalInvoker"
        this.$previousState.memo('modalInvoker');

        const modalScope = this.$rootScope.$new();

        modalScope.modal = {
            close: () => {
                modalScope.modal.modal.remove().then(() => {
                    // Return to previous state
                    this.$previousState.go('modalInvoker');
                });

                state.modalOpen = false;
            },
        };

        this.RareModal.fromTemplateUrl(template, {
            scope: modalScope,
        }).then((modal) => {
            // The StackView is created after the state change event so it has no way of
            // bootstrapping itself. By calling reload we can trick it into getting setup.
            this.$state.reload();

            modalScope.modal.modal = modal;

            setTimeout(() => {
                modal.show();
            }, 20);
        });
    }
}
