export default {
    'vessels-search-abstract': {
        parent: 'app',
        abstract: true,
        url: '/vessels/search',
        views: {
            app: {
                templateUrl: 'templates/vessels/search.html',
            },
        },
    },

    'vessels-search': {
        parent: 'vessels-search-abstract',
        url: '/results?:q',
        views: {
            'search-results': {
                controller: 'VesselsSearchController',
                controllerAs: 'vm',
                templateUrl: 'templates/vessels/search.results.html',
            },
        },
        params: {
            replaceInStackView: false,
            ignoreReplaceInStackView: false,
        },
        stackview: {
            ignoreParams: [
                'ignoreReplaceInStackView',
            ],
        },
        resolve: {
            vessels: (VesselsSearchCache, $stateParams) => {
                return VesselsSearchCache.getCacheQueryResults($stateParams.q);
            },
        },
    },

    'vessel-show': {
        parent: 'app',
        url: '/vessels/show/:imo',
        views: {
            app: {
                controller: 'VesselsShowController',
                controllerAs: 'vm',
                templateUrl: 'templates/vessels/show.html',
            },
        },
        resolve: {
            vessel: (Vessels, $stateParams) => {
                return Vessels.withIMO($stateParams.imo);
            },
        },
    },

    'vessel-show-location': {
        parent: 'app',
        url: '/vessels/show/:imo/location',
        views: {
            app: {
                controller: 'VesselsShowLocationController',
                controllerAs: 'vm',
                templateUrl: 'templates/vessels/location.html',
            },
        },
    },

    'vessel-unions': {
        parent: 'app',
        url: '/vessels/show/:imo/unions',
        views: {
            app: {
                controller: 'VesselUnionsController',
                controllerAs: 'vm',
                templateUrl: 'templates/vessels/unions.html',
            },
        },
        resolve: {
            unions: (Vessels, $stateParams) => {
                return Vessels.withIMO($stateParams.imo).then((vessel) => {
                    if (!vessel) {
                        return false;
                    }

                    return vessel.affiliates;
                });
            },
        },
    },
};
