/**
 * We need a way to see if we've already cached results from the API for doing a search. So that
 * our views can be stateful we can then resolve cached results in the route or perform the search
 * API request in the Controller
 */
export default class
{
    getCacheQueryResults(query) {
        return Promise.resolve().then(() => {
            return localStorage.getItem('vessel-search-cache-query') === query;
        }).then((hasCache) => {
            if (!hasCache) {
                return false;
            }

            return JSON.parse(localStorage.getItem('vessel-search-cache-results') || '');
        });
    }

    setCacheQueryResults(query, results) {
        return Promise.resolve().then(() => {
            localStorage.setItem('vessel-search-cache-results', JSON.stringify(results));
            localStorage.setItem('vessel-search-cache-query', query);
        });
    }

    clear() {
        return Promise.resolve().then(() => {
            localStorage.removeItem('vessel-search-cache-query');
            localStorage.removeItem('vessel-search-cache-results');
            console.log('VesselsSearchCache::clear()');
        });
    }
}
