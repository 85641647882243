ydn.debug.log('ydn', 900); // show warning for all ydn modules.

import 'babel-polyfill';

import './services';
import './factories';
import './controllers';
import './directives';
import './filters';
import AppConfigFactory from './config';

import '../www/lib/rare-stack-view/js/stackview';
import './api/API';
import './rareModal/module';

import { routes } from './routes';

// angular.module is a global place for creating, registering and retrieving Angular modules
// 'app' is the name of this angular module example (also set in a <body> attribute in
// index.html) the 2nd parameter is an array of 'requires'
// 'app.controllers' is found in ./controllers.js
// 'app.directives' is found in ./directives.js
// 'app.filters' is found in ./filters.js
// 'app.services' is found in ./services.js
angular.module('app', [
    'ui.router',
    'ct.ui.router.extras',
    'ngAnimate',
    'ngSanitize',
    'ngCordova',
    'app.controllers',
    'app.directives',
    'app.filters',
    'app.factories',
    'app.services',
    'rare.stackview',
    'itf.crm-api',
    'nemLogging',
    'ui-leaflet',
    'rare-modal',
    'moment-picker',
])

.run(($rootScope, DataSync, InspectionsQueue, Auth) => {
    console.log('Ready...');

    if ('cordova' in window && 'Keyboard' in cordova.plugins) {
        cordova.plugins.Keyboard.disableScroll(true);
    }

    Auth.init();

    DataSync.run();
    // InspectionsQueue.flush();


    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
        if (toState.authenticate && !Auth.isAuthenticated()) {
            // User isn’t authenticated
            console.log('need to auth');
            // $state.transitionTo("login");
            // event.preventDefault();
        }
    });
})


.config(($compileProvider) => {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|sms|tel):/);
})

.config(routes)

.factory('AppConfig', AppConfigFactory);

// Bootstrap the app after we're sure that everything we need has loaded. This differs depending on
// whether we're in the browser or using Cordova
/* eslint-disable no-console */
if (window.cordova) {
    console.log('Running in Cordova, will bootstrap AngularJS once `deviceready` event fires.');
    document.addEventListener('deviceready', () => {
        console.log('Deviceready event has fired, bootstrapping AngularJS.');
        angular.bootstrap(document.body, ['app']);
    }, false);
} else {
    console.log('Running in browser, bootstrapping AngularJS once `documentready` event fires.');
    angular.element(document).ready(() => {
        angular.bootstrap(document.body, ['app']);
    });
}
/* eslint-enable no-console */
