/**
 * Wrap <select> element so that we can add custom styling
 */
export default ($compile, $timeout) => {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            placeholder: '@',
        },

        template: `
            <ng-transclude></ng-transclude>
            <span class="rare-select__value" ng-class="{'rare-select__value--placeholder': selectedValue === placeholder}" role="presentation" aria-hidden="true">
                {{ selectedValue }}
            </span>
        `,

        link(scope, elem) {
            const selectElem = elem.find('select');
            const ngModelController = selectElem.controller('ngModel');

            scope.$watch(() => {
                return ngModelController.$viewValue;
            }, (value) => {
                $timeout(() => {
                    let found = false;

                    /* eslint-disable no-param-reassign */
                    selectElem[0].querySelectorAll('option').forEach((option) => {
                        if (option.value === value) {
                            scope.selectedValue = option.innerText;
                            found = true;
                        }
                    });

                    if (!found) {
                        scope.selectedValue = scope.placeholder;
                    }
                }, 20);
            });
        },
    };
};
