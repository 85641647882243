export default class
{
    constructor(rosters, $scope, Rosters) {
        this.rosters = rosters;

        $scope.$on('saveRoster', () => {
            Rosters.all().then((newRosters) => {
                this.rosters = newRosters;
            });
        });
    }
}
