export default class
{
    constructor($rootScope) {
        this.inspection = JSON.parse(localStorage.getItem('inspection-report')) || {};

        $rootScope.$watch(() => {
            return this.inspection;
        }, () => {
            localStorage.setItem('inspection-report', JSON.stringify(this.inspection));
        }, true);
    }

    data() {
        return this.inspection;
    }

    reset() {
        this.inspection = {
            date: moment().format(),
            portAccessDenied: 'no',
            vesselAccessDenied: 'no',
            followUpRequired: 'no',
        };

        this.documentationOptions().forEach((item) => {
            this.inspection[item.key] = 0;
        });

        return this.inspection;
    }

    documentationOptions() {
        return [
            {
                name: 'Ship Registration',
                key: 'shipRegistration',
            },
            {
                name: 'ITF Special Agreement',
                key: 'itfSpecialAgreement',
            },
            {
                name: 'Crew List',
                key: 'crewList',
            },
            {
                name: 'Ships Articles',
                key: 'shipsArticles',
            },
            {
                name: 'Crew Certification',
                key: 'crewCertification',
            },
            {
                name: 'Overtime Sheets',
                key: 'overtimeSheets',
            },
            {
                name: 'Manning Scale',
                key: 'manningScale',
            },
            {
                name: 'Insurance',
                key: 'insurance',
            },
            {
                name: 'Contracts of Employment',
                key: 'contractsOfEmployment',
            },
            {
                name: 'Health & Safety',
                key: 'healthAndSafety',
            },
            {
                name: 'Wage Accounts',
                key: 'wageAccounts',
            },
            {
                name: 'Other',
                key: 'other',
            },
        ];
    }
}
