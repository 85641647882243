import '../polyfills/closest';

function scrollTo(element, to, duration) {
    if (duration <= 0) {
        return;
    }

    const difference = to - element.scrollTop;
    const perTick = difference / duration * 16;

    requestAnimationFrame(() => {
        /* eslint-disable no-param-reassign */
        element.scrollTop = element.scrollTop + perTick;
        /* eslint-enable no-param-reassign */

        if (element.scrollTop === to) {
            return;
        }

        scrollTo(element, to, duration - 16);
    });
}

const scrollToElement = (elem, scrollContext) => {
    const elemTop = elem.getBoundingClientRect().top;
    const scrollContextTop = scrollContext.getBoundingClientRect().top;
    const currentScrollTop = scrollContext.scrollTop;

    scrollTo(scrollContext, elemTop - scrollContextTop + currentScrollTop, 200);
};

export default {
    processFormErrors(form, errors) {
        /* eslint-disable no-param-reassign */
        errors.forEach((error) => {
            if (!form[error.field]) {
                return;
            }

            form[error.field].$setValidity('server', false);
            form[error.field].serverMessage = error.details;
        });
        /* eslint-enable no-param-reassign */
    },

    setAllErrorFieldsAsDirty(form) {
        angular.forEach(form.$error, (field) => {
            angular.forEach(field, (errorField) => {
                errorField.$setDirty();
            });
        });
    },

    setAllFieldsAsUntouched(form) {
        angular.forEach(form, function (input) {
            if (input && input.hasOwnProperty('$viewValue')) {
              input.$setUntouched();
            }
        });
    },

    scrollToFirstError() {
        setTimeout(() => {
            const firstError = document.querySelector('.itf-form-field--error-add, .itf-form-field--error, .itf-list-header--error-add, itf-list-header--error');

            if (firstError) {
                const scrollContext = firstError.closest('.rare-scroll') || document.body;

                scrollToElement(firstError, scrollContext);
            }
        }, 50);
    },
};
