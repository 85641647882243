export default class
{
    constructor(API, $scope, VesselsSearchCache, $state, vessels, $stateParams, Vessels, $cordovaDialogs) {
        this.API = API;
        this.VesselsSearchCache = VesselsSearchCache;
        this.VesselsService;
        this.$state = $state;
        this.$cordovaDialogs = $cordovaDialogs;

        this.vessels = vessels;
        this.searchQuery = $stateParams.q;

        // Tidy up after ourselves
        if (!this.searchQuery) {
            this.VesselsSearchCache.clear();
        }

        $scope.$on('searchbox:update', (event, input) => {
            // Wait until we've had at least 3 characters before searching
            // But also clear the search if we go back down to zero length
            if (input.length !== 0 && input.length < 3) {
                return;
            }

            this.$state.go(this.$state.current, {
                q: input,
                replaceInStackView: true,
            });
        });

        if (!this.vessels && $stateParams.q) {
            this.performSearch($stateParams.q);
        } else {
            console.log(this.vessels);
            this.sortVessels();
        }
    }

    sortVessels() {
        if (!this.vessels) {
            return;
        }

        this.vessels = this.vessels.sort((vesselA, vesselB) => {
            return vesselA.name.localeCompare(vesselB.name);
        });
    }

    performSearch(input) {
        this.loading = true;

        const isIMOLike = /^[0-9]+$/.test(input);

        const searchType = isIMOLike ? 'imo' : 'name';

        this.API.getVessels(input, searchType).then((response) => {
            console.log(response.data);
            this.vessels = response.data;
            this.sortVessels();

            // Save a copy in the db as a cache that we can pull from when we come back to this
            // screen from the details view
            this.VesselsSearchCache.setCacheQueryResults(input, this.vessels).then(() => {
                console.log('Updated vessel cache');
            });
        }).catch((error) => {
            // For some reason the API returns a 404 when the string doesn't match - madness!
            if (error.status === 404) {
                this.vessels = [];
            } else if (error.status === 400) {
                this.vessels = [];

                this.$cordovaDialogs.alert(
                    'Oops, there was a problem searching vessels. Please try again later.',
                    'Unable to search vessels'
                );
            } else {
                throw error;
            }
        }).finally(() => {
            this.loading = false;
        });
    }
}
