export default class
{
    constructor(inspections, InspectionsQueue, Auth, $state, Inspections) {
        this.Inspections = Inspections;
        this.InspectionsQueue = InspectionsQueue;
        this.Auth = Auth;
        this.$state = $state;

        this.isAuthenticated = this.Auth.isAuthenticated();

        this.inspections = inspections.sort((a, b) => {
            return a.date > b.date ? -1 : 1;
        });
    }

    sendToServer() {
        this.loading = true;

        this.InspectionsQueue.flush().then(() => {
            this.$cordovaDialogs.alert(
                'All inspections have been delivered.',
                'Inspections Sent'
            ).then(() => {
                this.$state.go('home');
            });
        }).catch(() => {
            this.$cordovaDialogs.alert(
                'One or more inspection failed to send, please try again later.',
                'Inspections Sending Failed'
            );
        }).finally(() => {
            this.Inspections.all((inspections) => {
                this.inspections = inspections;
            });

            this.loading = false;
        });
    }

    authenticate() {
        this.Auth.authenticate().then(() => {
            this.isAuthenticated = this.Auth.isAuthenticated();
        }).catch((error) => {
            console.error(error);
        });
    }
}
