/**
 * Maintain the scroll position of scroll elements when transitioning between states
 *
 * Limitiation - this only works if there is at most 1 scroll view per screen
 */
export default ($state) => {
    // Data structure to keep track of the scroll positions for each screen
    const scrollPosCache = {};

    return {
        restrict: 'CAE',

        controller() {
            /**
             * Remove what we know about scroll position for a given href
             *
             * @param  {String} href The href to forget
             */
            this.forgetPositionForHref = (href) => {
                delete scrollPosCache[href];
            };
        },

        link(scope, element) {
            const href = $state.href($state.current.name, $state.params);

            // We need to wait till the UI-Router has completed the transition so that we know all
            // the directives and templates have been rendered
            scope.$on('$stateChangeSuccess', () => {
                const startingScrollPos = scrollPosCache[href];

                if (startingScrollPos) {
                    setTimeout(() => {
                        /* eslint-disable no-param-reassign */
                        element[0].scrollTop = startingScrollPos;
                        /* eslint-enable no-param-reassign */
                    });
                }
            });

            // Update the cached scroll position for this page
            element.on('scroll', () => {
                scrollPosCache[href] = element[0].scrollTop;
            });
        },
    };
};
