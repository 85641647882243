export default {
    'vessel-inspection-abstract': {
        url: '/inspection',
        abstract: true,
        template: '<div ui-view></div>',

        /**
         * Make this route load in a modal window
         */
        onEnter(RouteModal) {
            RouteModal.setup(this, 'templates/inspections/add.html');
        },
    },

    'vessel-inspection': {
        parent: 'vessel-inspection-abstract',
        url: '/details',

        authenticate: true,
        views: {
            'inspection@': {
                controller: 'InspectionDetailsController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspections/details.html',
            },
        },

        params: {
            create: false,
        },

        stackview: {
            // We're using `create` as a way to flag whether or not this is the first time that this
            // screen has been shown as part of a workflow. As such we don't want this to be used
            // as a comparison for whether states are different or not
            ignoreParams: [
                'create',
            ],
        },

        resolve: {
            vessel: (Vessels, $stateParams) => {
                return Vessels.withIMO($stateParams.imo);
            },

            ports: (Ports) => {
                return Ports.all();
            },

            reasons: (Lookups) => {
                return Lookups.allInspectionReasons();
            },

            types: (Lookups) => {
                return Lookups.allInspectionTypes();
            },
        },


    },

    'vessel-inspection-documentation': {
        parent: 'vessel-inspection-abstract',
        url: '/documentation',
        authenticate: true,
        views: {
            'inspection@': {
                controller: 'InspectionDocumentationController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspections/documentation.html',
            },
        },
    },

    'vessel-inspection-notes': {
        parent: 'vessel-inspection-abstract',
        url: '/notes',
        authenticate: true,
        views: {
            'inspection@': {
                controller: 'InspectionNotesController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspections/notes.html',
            },
        },
    },

    'vessel-inspection-ports-abstract': {
        parent: 'vessel-inspection-abstract',
        url: '/ports',
        abstract: true,
        authenticate: true,
        views: {
            'inspection@': {
                // controller: 'InspectionDetailsController',
                // controllerAs: 'vm',
                templateUrl: 'templates/inspections/ports/search.html',
            },
        },
    },

    'vessel-inspection-ports': {
        parent: 'vessel-inspection-ports-abstract',
        url: '/results?:q',
        views: {
            'search-results': {
                controller: 'PortsSearchController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspections/ports/search.results.html',
            },
        },
        params: {
            replaceInStackView: false,
        },
    },

    'vessel-inspections-list': {
        parent: 'app',
        url: '/inspections',
        views: {
            app: {
                controller: 'UnsentInspectionsController',
                controllerAs: 'vm',
                templateUrl: 'templates/inspections/unsent.html',
            },
        },
        resolve: {
            inspections: (Inspections) => {
                return Inspections.all();
            }
        },
    },
};
