export default class
{
    constructor(roster, Rosters, $cordovaDialogs, $scope) {
        this.$cordovaDialogs = $cordovaDialogs;
        this.roster = roster;
        this.Rosters = Rosters;
        this.$scope = $scope;
    }

    deleteRoster() {
        this.$cordovaDialogs.confirm(
            'Are you sure you want to delete this roster?',
            'Delete Roster',
            ['Delete', 'Cancel']
        ).then((buttonIndex) => {
            // If the user pressed 'Delete', then remove the marker and close the modal
            if (buttonIndex === 1) {
                this.Rosters.delete(this.roster);
                this.$scope.$emit('deleteRoster', this.roster);
                this.$scope.$emit('stackView:goBack');
            }
        });
    }
}
