export default () => {
    const seen = (value) => {
        return value >= 1;
    };

    const copyTaken = (value) => {
        return value === 2;
    };

    return {
        outbound(input) {
            return {
                Port: input.port ? input.port.id : '',
                InspectionDate: moment(input.date).format(),
                Vessel: input.vessel ? input.vessel.id : '',
                CaseOrigin: input.reasonId,
                CaseType: input.typeId,
                AccessDeniedatPort: input.portAccessDenied === 'yes',
                AccessDeniedatVessel: input.vesselAccessDenied === 'yes',
                FollowUpActionRequired: input.followUpRequired === 'yes',
                DocumentationNotes: input.notes,

                CrewListSeen: seen(input.crewList),
                CrewListCopyTaken: copyTaken(input.crewList),

                ShipRegistrationSeen: seen(input.shipRegistration),
                ShipRegistrationCopyTaken: copyTaken(input.shipRegistration),

                ITFSpecialAgreementSeen: seen(input.itfSpecialAgreement),
                ITFSpecialAgreementCopyTaken: copyTaken(input.itfSpecialAgreement),

                ContractsofEmploymentSeen: seen(input.contractsOfEmployment),
                ContractsofEmploymentCopyTaken: copyTaken(input.contractsOfEmployment),

                WageAccountsSeen: seen(input.wageAccounts),
                WageAccountsCopyTaken: copyTaken(input.wageAccounts),

                ShipsArticlesSeen: seen(input.shipsArticles),
                ShipsArticlesCopyTaken: copyTaken(input.shipsArticles),

                CrewCertificationSeen: seen(input.crewCertification),
                CrewCertificationCopyTaken: copyTaken(input.crewCertification),

                OvertimeSheetsSeen: seen(input.overtimeSheets),
                OvertimeSheetsCopyTaken: copyTaken(input.overtimeSheets),

                ManningScaleSeen: seen(input.manningScale),
                ManningScaleCopyTaken: copyTaken(input.manningScale),

                InsuranceSeen: seen(input.InsuranceSeen),
                InsuranceCopyTaken: copyTaken(input.InsuranceSeen),

                HealthAndSafetySeen: seen(input.healthAndSafety),
                HealthAndSafetyCopyTaken: copyTaken(input.healthAndSafety),

                OtherSeen: seen(input.other),
                OtherCopyTaken: copyTaken(input.other),
            };
        },
    };
};
