import trim from './helpers/trim';

export default () => {
    return {
        inbound(input) {
            return {
                id: trim(input.id),
                country: {
                    code: trim(input.ISO3CountryCode),
                    name: trim(input.Country),
                },
                location: {
                    lat: input.Latitude,
                    lng: input.Longitude,
                    url: input.LocationURL ? input.LocationURL.replace('&output=embed', '') : null,
                },
                name: trim(input.Name),
                telephone: trim(input.Telephone),
                fax: trim(input.Fax),
                email: trim(input.EmailAddress),
                address: {
                    line1: trim(input.AddressLine1),
                    line2: trim(input.AddressLine2),
                    line3: trim(input.AddressLine3),
                    line4: trim(input.AddressLine4),
                    city: trim(input.AddressCity),
                    county: trim(input.AddressCounty),
                    postcode: trim(input.AddressPostCode),
                    country: trim(input.AddressCountry),
                },
                appEmail: trim(input.AppsEmail),
            };
        },
    };
};
