export default class
{
    constructor(MarineTraffic, $stateParams, $cordovaDialogs, $state, $scope) {
        this.loading = true;
        this.$cordovaDialogs = $cordovaDialogs;

        // By default the leaflet directive uses `//` which is fine on the web and picks the correct
        // http or https. However when we run in Cordova, this ends up being file://
        this.tiles = {
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            options: {
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }
        }

        this.center = {
            zoom: 8,
        };

        // Uniform way of handling the case when we can't get location data for a vessel
        const mtFailure = () => {
            this.$cordovaDialogs.alert(
                'The current location of this vessel can not be determined at this time.',
                'Location Unavailable'
            ).then(() => {
                $state.go('vessel-show', {
                    imo: $stateParams.imo,
                });
            });
        };

        this.cancelled = false;

        // See if we can get the location data for a vessel from Marine Traffic
        MarineTraffic.lookupVessel($stateParams.imo).then((ship) => {
            if (this.cancelled) {
                return;
            }

            if (!ship) {
                mtFailure();
                return;
            }

            this.markers = {
                position: {
                    lat: ship.lat,
                    lng: ship.lng,
                },
            };

            this.center = {
                lat: ship.lat,
                lng: ship.lng,
                zoom: 6,
            };

            this.ports = [];

            if (ship.currentPort) {
                this.ports.push({
                    label: 'Current Port',
                    value: ship.currentPort,
                });
            }

            if (ship.lastPort) {
                this.ports.push({
                    label: 'Previous Port',
                    value: ship.lastPort,
                });
            }

            if (ship.nextPort) {
                this.ports.push({
                    label: 'Next Port',
                    value: ship.nextPort,
                });
            }
        }).catch(mtFailure).finally(() => {
            this.loading = false;
        });

        $scope.$on('$destroy', () => {
            this.cancelled = true;
        });
    }
}
