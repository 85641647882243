export default {
    home: {
        parent: 'app',
        url: '/home',
        views: {
            app: {
                controller: 'HomeController',
                controllerAs: 'vm',
                templateUrl: 'templates/home.html',
            },
        },
        resolve: {
            inspections: (Inspections) => {
                return Inspections.all();
            }
        },
    },
};
