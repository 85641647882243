export default class
{
    constructor(options) {
        this.el = options.el;
        this.$el = angular.element(options.modalEl);
        this.animation = options.animation || 'slide-in-up';
        this.scope = options.scope;
    }

    /**
     * Show the modal
     *
     * @return {Promise}
     */
    show() {
        this.isVisible = true;

        this.$el.removeClass('hide');

        angular.element(document.body).append(this.$el);

        return new Promise((fulfil) => {
            setTimeout(() => {
                // Add the initial classes
                this.$el.addClass('ng-enter active').removeClass('ng-leave ng-leave-active');

                // Add the active class
                setTimeout(() => {
                    this.$el.addClass('ng-enter-active');
                }, 20);
            }, 20);

            setTimeout(() => {
                if (this.scope.$parent) {
                    this.scope.$parent.$broadcast('modal.shown');
                }

                fulfil();
            }, 400);
        });
    }

    /**
     * Hide the modal
     *
     * @return {Promise}
     */
    hide() {
        this.$el.removeClass('active');
        this.$el.addClass('ng-leave');

        setTimeout(() => {
            this.$el.addClass('ng-leave-active')
                        .removeClass('ng-enter ng-enter-active active');
        }, 20);

        return new Promise((fulfil) => {
            setTimeout(() => {
                this.isVisible = false;
                this.$el.addClass('hide');

                if (this.scope.$parent) {
                    this.scope.$parent.$broadcast('modal.hidden');
                }

                fulfil();
            }, 400);
        });
    }

    /**
     * Remove the modal, will hide first if it is visible
     *
     * @return {Promise}
     */
    remove() {
        const promise = this.isVisible ? this.hide() : Promise.resolve();

        return promise.then(() => {
            this.scope.$destroy();
            this.$el.remove();

            if (this.scope.$parent) {
                this.scope.$parent.$broadcast('modal.removed');
            }

        });
    }
}
