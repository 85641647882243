export default class
{
    constructor(Database, $q) {
        this.$q = $q;
        this.Database = Database;
    }

    fetch(id) {
        return this.$q.when(this.Database.get('ports', id));
    }

    all() {
        return this.$q.when(this.Database.from('ports').list(10000));
    }

    search(searchQuery) {
        const q = searchQuery.toLowerCase();
        return this.all().then((ports) => {
            return ports.filter((port) => {
                return port.name.toLowerCase().indexOf(q) !== -1;
            });
        });
    }

    store(vessel) {
        return this.$q.when(this.Database.put('ports', vessel));
    }

    /**
     * Clear all records from this store and replace with the items that have been passed in
     *
     * @param  {Array} records Array of objects to store
     * @return {Promise}
     */
    replaceRecords(records) {
        const promise = this.Database.clear('ports').then(() => {
            return this.Database.putAll('ports', records);
        });

        return this.$q.when(promise);
    }

    clear() {
        return this.Database.clear('ports');
    }
}
