export default class
{
    constructor(union) {
        this.union = union;

        // By default the leaflet directive uses `//` which is fine on the web and picks the correct
        // http or https. However when we run in Cordova, this ends up being file://
        this.tiles = {
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            options: {
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }
        }

        this.markers = {
            position: {
                lat: this.union.location.lat,
                lng: this.union.location.lng,
            },
        };

        this.center = {
            lat: this.union.location.lat,
            lng: this.union.location.lng,
            zoom: 10,
        };
    }
}
