// Simple debounce function inspired by https://gist.github.com/vincentorback/9649034
// Example Usage: window.addEventListener('resize', debounce(() => {}, 500);
export default (fn, wait) => {
    let timeout;
    return () => {
        clearTimeout(timeout);

        timeout = setTimeout((...args) => {
            return fn(args);
        }, (wait || 1));
    };
};
