import HomeController from './controllers/HomeController';
import InspectorsAndUnionsSearchController from './controllers/InspectorsAndUnionsSearchController';
import InspectorsAndUnionsShowController from './controllers/InspectorsAndUnionsShowController';
import InspectorsAndUnionsShowInspectorsController from './controllers/InspectorsAndUnionsShowInspectorsController';
import InspectorsAndUnionsShowUnionsController from './controllers/InspectorsAndUnionsShowUnionsController';
import InspectorController from './controllers/InspectorController';
import UnionController from './controllers/UnionController';
import UnionMapController from './controllers/UnionMapController';
import VesselsSearchController from './controllers/VesselsSearchController';
import VesselsShowController from './controllers/VesselsShowController';
import VesselsShowLocationController from './controllers/VesselsShowLocationController';
import VesselUnionsController from './controllers/VesselUnionsController';
import TimezonesController from './controllers/TimezonesController';
import RostersController from './controllers/RostersController';
import RosterShowController from './controllers/RosterShowController';
import RosterDetailsController from './controllers/RosterDetailsController';
import RosterHoursController from './controllers/RosterHoursController';
import HoursEditController from './controllers/HoursEditController';
import RosterAddController from './controllers/RosterAddController';
import InspectionDetailsController from './controllers/InspectionDetailsController';
import InspectionNotesController from './controllers/InspectionNotesController';
import InspectionDocumentationController from './controllers/InspectionDocumentationController';
import PortsSearchController from './controllers/PortsSearchController';
import ResourcesController from './controllers/ResourcesController';
import ITFOfficesController from './controllers/ITFOfficesController';
import ITFOfficeController from './controllers/ITFOfficeController';
import ContactController from './controllers/ContactController';
import UnsentInspectionsController from './controllers/UnsentInspectionsController';

angular.module('app.controllers', [])
    .controller('HomeController', HomeController)
    .controller('InspectorsAndUnionsSearchController', InspectorsAndUnionsSearchController)
    .controller('InspectorsAndUnionsShowController', InspectorsAndUnionsShowController)
    .controller('InspectorsAndUnionsShowInspectorsController', InspectorsAndUnionsShowInspectorsController)
    .controller('InspectorsAndUnionsShowUnionsController', InspectorsAndUnionsShowUnionsController)
    .controller('InspectorController', InspectorController)
    .controller('UnionController', UnionController)
    .controller('UnionMapController', UnionMapController)
    .controller('VesselsSearchController', VesselsSearchController)
    .controller('VesselsShowController', VesselsShowController)
    .controller('VesselsShowLocationController', VesselsShowLocationController)
    .controller('VesselUnionsController', VesselUnionsController)
    .controller('TimezonesController', TimezonesController)
    .controller('RostersController', RostersController)
    .controller('RosterShowController', RosterShowController)
    .controller('RosterDetailsController', RosterDetailsController)
    .controller('RosterHoursController', RosterHoursController)
    .controller('HoursEditController', HoursEditController)
    .controller('RosterAddController', RosterAddController)
    .controller('InspectionDetailsController', InspectionDetailsController)
    .controller('InspectionNotesController', InspectionNotesController)
    .controller('InspectionDocumentationController', InspectionDocumentationController)
    .controller('PortsSearchController', PortsSearchController)
    .controller('ResourcesController', ResourcesController)
    .controller('ITFOfficesController', ITFOfficesController)
    .controller('ITFOfficeController', ITFOfficeController)
    .controller('ContactController', ContactController)
    .controller('UnsentInspectionsController', UnsentInspectionsController)
;

