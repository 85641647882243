export default () => {
    return {
        restrict: 'E',

        template: `
            <div class="rare-modal-backdrop">
                <div class="rare-modal-background"></div>
                <div class="rare-modal-wrapper" ng-transclude></div>
            </div>
        `,

        transclude: true,

        link: (scope, element, attrs) => {

        },
    };
};
