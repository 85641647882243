export default class
{
    constructor(roster, $scope, Rosters) {
        this.roster = roster;

        $scope.$on('saveHours', (event, hours, thisRoster) => {
            Rosters.storeHours(hours, thisRoster);
            $scope.$broadcast('hoursChanged', thisRoster);
        });

        $scope.$on('deleteHours', (event, hours, thisRoster) => {
            Rosters.deleteHours(hours);
            $scope.$broadcast('hoursChanged', thisRoster);
        });
    }
}
