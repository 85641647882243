export default class
{
    constructor(API, Lookups, Inspectors, AffiliateUnions, Ports, Offices) {
        this.API = API;
        this.Lookups = Lookups;
        this.Inspectors = Inspectors;
        this.AffiliateUnions = AffiliateUnions;
        this.Ports = Ports;
        this.Offices = Offices;
    }

    run() {
        const lastChecked = localStorage.getItem('last-server-sync-time');

        this.API.getSyncStatus(lastChecked).then((response) => {
            const status = response.data;

            // Keep track of all the requests that we're supposed to be making. Only if ALL of them
            // pass will we say that this was successful. If successful we'll make a note of the
            // current date/time so that we can use this for future requests
            const promises = [];

            if (status.lookupsCount) {
                console.log('Updating Lookups from API');
                promises.push(this.API.getLookups().then((lookups) => {
                    this.Lookups.replaceRecords(lookups.data);
                }));
            }

            if (status.officesCount) {
                console.log('Updating Offices from API');
                promises.push(this.API.getOffices().then((offices) => {
                    this.Offices.replaceRecords(offices.data);
                }));
            }

            if (status.inspectorsCount) {
                console.log('Updating Inspectors from API');
                promises.push(this.API.getInspectors().then((inspectors) => {
                    this.Inspectors.replaceRecords(inspectors.data);
                }));
            }

            if (status.affiliatesCount) {
                console.log('Updating Affiliates from API');
                promises.push(this.API.getAffiliateUnions().then((unions) => {
                    this.AffiliateUnions.replaceRecords(unions.data);
                }));
            }

            if (status.portsCount) {
                console.log('Updating Ports from API');
                promises.push(this.API.getPorts().then((ports) => {
                    this.Ports.replaceRecords(ports.data);
                }));
            }

            Promise.all(promises).then(() => {
                localStorage.setItem('last-server-sync-time', moment().format());
            });
        });
    }
}
