export default class
{
    constructor(Database, $q) {
        this.$q = $q;
        this.Database = Database;
    }

    fetch(id) {
        return this.$q.when(this.Database.get('offices', id));
    }

    all() {
        return this.$q.when(this.Database.from('offices').list(10000));
    }

    store(vessel) {
        return this.$q.when(this.Database.put('offices', vessel));
    }

    /**
     * Clear all records from this store and replace with the items that have been passed in
     *
     * @param  {Array} records Array of objects to store
     * @return {Promise}
     */
    replaceRecords(records) {
        const promise = this.Database.clear('offices').then(() => {
            return this.Database.putAll('offices', records);
        });

        return this.$q.when(promise);
    }

    clear() {
        return this.Database.clear('offices');
    }
}
