export default class
{
    constructor(Database, $q) {
        this.$q = $q;
        this.Database = Database;
    }

    fetch(id) {
        return this.$q.when(this.Database.get('rosters', id));
    }

    all(countryCode) {
        const promise = this.Database
            .from('rosters')
            .list(10000);

        return this.$q.when(promise);
    }

    store(data) {
        return this.$q.when(this.Database.put('rosters', data));
    }

    delete(roster) {
        const rosterId = typeof roster === 'object' ? roster.id : roster;

        return this.$q.when(this.Database.remove('rosters', rosterId));
    }

    allHoursForRoster(roster) {
        const rosterId = typeof roster === 'object' ? roster.id : roster;

        const promise = this.Database
            .from('roster-hours')
            .where('rosterId', '=', parseInt(rosterId, 10))
            .list(10000);

        return this.$q.when(promise);
    }

    storeHours(hours, roster) {
        const hoursCopy = angular.copy(hours);
        hoursCopy.rosterId = roster.id;

        return this.$q.when(this.Database.put('roster-hours', hoursCopy));
    }

    deleteHours(hours) {
        const hoursId = typeof hours === 'object' ? hours.id : hours;

        return this.$q.when(this.Database.remove('roster-hours', hoursId));
    }
}
