import trim from './helpers/trim';
import array from './helpers/array';

export default (AffiliateUnionTransformer) => {
    const booleanHelper = (input) => {
        return input === 'Y';
    };

    const detailListInbound = (input) => {
        const crewBreakdown = trim(input.LatestCrewList) || '';
        let crewDate = '';
        let crewNationalities = '';

        [crewDate, crewNationalities] = crewBreakdown.split(':');

        return {
            id: trim(input.$id),
            affiliatedMaritimeUnions: trim(input.AffiliatedMaritimeUnions),
            agreementStatus: trim(input.AgreementStatus),
            displayMessage: trim(input.DisplayMessage),
            imo: trim(input.IMONumber),
            crew: {
                date: trim(crewDate),
                nationalities: trim(crewNationalities),
                count: trim(input.TotalCrew),
            },
            vesselAppStatus: trim(input.VesselAppStatus),
        };
    };

    const agreementListInbound = (input) => {
        return {
            id: trim(input.$id),
            status: trim(input.AgreementStatus),
            type: trim(input.AgreementType),
            startDate: trim(input.StartDate),
            endDate: trim(input.EndDate),
            signatoryAffiliate: {
                name: trim(input.SignatoryAffiliate),
                id: trim(input.SignatoryAffiliateGUID),
            },
            signatoryUnion: trim(input.SignatoryUnion),
        };
    };

    const vesselInspectionListInbound = (input) => {
        return {
            date: trim(input.InspectionDate),
            port: trim(input.Port),
            country: trim(input.Country),
        };
    };

    return {
        inbound(input) {
            return {
                id: trim(input.VesselID),
                imo: trim(input.IMONumber),
                name: trim(input.VesselName),
                flag: trim(input.VesselFlag),
                affiliateCountry: trim(input.AffiliateCountry),

                // What is this?
                mobileAppsFlagType: input.MobileAppsFlagType,

                type: trim(input.VesselType),
                isCruiseVessel: booleanHelper(input.IsCruiseVessel),
                isOffshoreVessel: booleanHelper(input.IsOffshoreVessel),
                isNationalFlag: booleanHelper(input.IsNationalFlag),
                hasLiveAgreement: booleanHelper(input.HasLiveAgreement),
                hasProspectAgreement: booleanHelper(input.HasProspectAgreement),
                hasTerminatedAgreement: booleanHelper(input.HasTerminatedAgreement),
                hasExpiredAgreement: booleanHelper(input.HasExpiredAgreement),
                hasAbandonedAgreement: booleanHelper(input.HasAbandonedAgreement),
                agreementType: trim(input.AgreementType),
                details: array(input.VesselDetailList).map(detailListInbound),
                agreements: array(input.VesselAgreementList).map(agreementListInbound),
                inspections: array(input.VesselinspectionList).map(vesselInspectionListInbound),
                affiliates: array(input.VesselAffiliateList).map(AffiliateUnionTransformer.inbound),
            };
        },
    };
};

