export default class
{
    constructor($scope, $interval) {
        this.times = [];

        this.timezones = [
            {
                key: 'America/Los_Angeles',
                name: 'Los Angeles',
            },
            {
                key: 'America/New_York',
                name: 'New York',
            },
            {
                key: 'America/Sao_Paulo',
                name: 'Sao Paulo',
            },
            {
                key: 'Europe/London',
                name: 'London',
            },
            {
                key: 'Europe/Warsaw',
                name: 'Warsaw',
            },
            {
                key: 'Africa/Harare',
                name: 'Harare',
            },
            {
                key: 'Asia/Istanbul',
                name: 'Istanbul',
            },
            {
                key: 'Europe/Moscow',
                name: 'Moscow',
            },
            {
                key: 'Asia/Calcutta',
                name: 'Calcutta',
            },
            {
                key: 'Asia/Jakarta',
                name: 'Jakarta',
            },
            {
                key: 'Asia/Hong_Kong',
                name: 'Hong Kong',
            },
            {
                key: 'Asia/Shanghai',
                name: 'Shanghai',
            },
            {
                key: 'Asia/Manila',
                name: 'Manila',
            },
            {
                key: 'Asia/Tokyo',
                name: 'Tokyo',
            },
            {
                key: 'Australia/Sydney',
                name: 'Sydney',
            },
            {
                key: 'Asia/Vladivostok',
                name: 'Vladivostok',
            },
        ];

        this.updateTimes();

        this.timer = $interval(() => {
            this.updateTimes();
        }, 1000);

        // Remove timers when we kill this controller
        $scope.$on('$destroy', () => {
            $interval.cancel(this.timer);
            console.log('Timezones timer removed');
        });
    }

    updateTimes() {
        const now = moment();

        const currentTime = now.format('HH:mm');

        // Try and save thrashing the view and updating every second if we know we're not on a
        // different minute to when we last checked.
        // TL;DR: Check every second, update DOM once a minute
        if (currentTime === this.lastTime) {
            return;
        }

        this.times = [];
        this.lastTime = currentTime;

        this.timezones.forEach((timezone) => {
            this.times.push({
                key: timezone.key,
                name: timezone.name,
                time: now.tz(timezone.key).format('HH:mm'),
                offset: now.tz(timezone.key).format('Z'),
            });
        });
    }
}
