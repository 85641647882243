export default class
{
    constructor($stateParams, API, $state, Vessels, vessel, Auth, $cordovaDialogs, $rootScope) {
        this.$state = $state;
        this.Auth = Auth;

        this.vessel = vessel;
        this.isAuthenticated = this.Auth.isAuthenticated();
        this.loading = true;

        // Always refresh the data we have, incase what we have in the DB is stale
        API.getVesselWithIMO($stateParams.imo).then((response) => {
            this.vessel = response.data;
            Vessels.store(this.vessel);
        }).catch((error) => {
            console.error(error);
            this.loadingFailed = true;

            let message = 'Could not retrieve vessel details. Either you do not have a network connection or the service is temporarily unavailable.';

            if (error.status) {
                // We got a response, it just wasn't something we could deal with
                message = 'An unexpected error occured when retrieving this vessels details.';

                if (error.status === 404) {
                    message = 'A vessel with the requested IMO could not be found.';
                }
            }

            $cordovaDialogs.alert(
                message,
                'Unable to retrieve Vessel details'
            ).then(() => {
                $rootScope.$broadcast('stackView:goBack');
            });
        }).finally(() => {
            this.loading = false;
        });
    }

    showAffiliates() {
        this.$state.go('vessel-unions', {
            imo: this.vessel.imo,
        });
    }

    authenticate() {
        console.log('authenticate');

        this.Auth.authenticate().then(() => {
            this.isAuthenticated = this.Auth.isAuthenticated();
        }).catch((error) => {
            console.log(error);
        });
    }

    showVesselLocation() {
        console.log('showVesselLocation');
        this.$state.go('vessel-show-location', {
            imo: this.vessel.imo,
        });
    }
}
