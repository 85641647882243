import trim from './helpers/trim';

export default () => {
    return {
        inbound(input) {
            return {
                affiliatesCount: input.AffiliateCountSum,
                inspectorsCount: input.InspectorCountSum,
                officesCount: input.OfficeCountSum,
                inspectorUsersCount: input.InspectorUserCountSum,
                portsCount: input.PortCountSum,
                lookupsCount: input.OptionSetCountSum,
                latestServerSyncDate: input.LatestServerSynch,
            };
        },
    };
};
