import trim from './helpers/trim';

export default () => {
    return {
        inbound(input) {
            return {
                id: input.id,
                entityName: trim(input.EntityName),
                attributeName: trim(input.AttributeName),
                attributeValue: trim(input.AttributeValue),
                displayOrder: input.DisplayOrder,
            };
        },
    };
};
