export default () => {
    return {
        restrict: 'E',
        require: '^rareStackView',
        transclude: true,

        scope: {
            canGoBack: '&',
            overwriteParams: '=',
        },

        template: '<button class="rare-stack-view-back-button" ng-click="goBack()" ng-transclude></button>',

        link: (scope, element, attr, ctrl) => {
            scope.goBack = () => {
                if (attr.canGoBack) {
                    const returnValue = scope.canGoBack();

                    const isPromise = typeof returnValue.then === 'function';

                    if (isPromise) {
                        returnValue.then(() => {
                            ctrl.goBack(scope.overwriteParams);
                        });
                    } else {
                        if (returnValue) {
                            ctrl.goBack(scope.overwriteParams);
                        }
                    }
                } else {
                    ctrl.goBack(scope.overwriteParams);
                }
            };
        },
    };
};
