/**
 * Integrate the stack view with a scroll view
 */
export default ($state, $rootScope) => {
    return {
        require: '^rareScroll',
        restrict: 'CAE',

        link(scope, element, attrs, ctrl) {
            // Listen for when a StackView removes an item from the stack, at which point we need
            // to tell the scroll view to forget about the outbound view so that we don't maintain
            // the scroll position
            $rootScope.$on('stackViewPop', (event, state) => {
                ctrl.forgetPositionForHref($state.href($state.current.name, $state.params));
            });
        },
    };
};
