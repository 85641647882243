import activated from './directives/activated/directive';
import rareHref from './directives/RareHref/directive';
import rareScroll from './directives/rareScroll/directive';
import rareScrollWithStackView from './directives/rareScrollWithStackView/directive';
import itfSearchBox from './directives/itfSearchBox/directive';
import rareSelect from './directives/rareSelect/directive';
import datePicker from './directives/datePicker/directive';
import autoHeight from './directives/autoHeight/directive';
import autofocus from './directives/autofocus/directive';

angular.module('app.directives', [])
    .directive('button', activated)
    .directive('itfItem', activated)
    .directive('rareHref', rareHref)
    .directive('rareScroll', rareScroll)
    .directive('rareScroll', rareScrollWithStackView)
    .directive('itfSearchBox', itfSearchBox)
    .directive('rareSelect', rareSelect)
    .directive('itfDatePicker', datePicker)
    .directive('itfAutoHeight', autoHeight)
    .directive('autofocus', autofocus)
;
