import debounce from '../helpers/debounce';

export default class
{
    constructor(InspectionReport) {
        this.inspection = InspectionReport.data();
        this.documentation = InspectionReport.documentationOptions();
    }

    toggleKey(key) {
        this.inspection[key]++;

        if (this.inspection[key] > 2) {
            this.inspection[key] = 0;
        }
    }
}
