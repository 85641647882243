export default class
{
    constructor(inspectors) {
        this.inspectors = inspectors.sort((a, b) => {
            const categoryOrder = {
                'Coordinator': 4,
                'Assistant Coordinator': 3,
                'Inspector': 2,
                'Contact': 1,
            };

            // Sort by job category: Coordinator, Assistant Coordinator, Inspector, Contact
            const aCategoryOrder = categoryOrder[a.job.category] || 0;
            const bCategoryOrder = categoryOrder[b.job.category] || 0;

            const order = bCategoryOrder - aCategoryOrder;

            // Sort same category by forename
            if (order === 0) {
                return a.fullname.localeCompare(b.fullname);
            }

            return order;
        });
    }
}
