export default class
{
    constructor($http, AppConfig) {
        this.$http = $http;
        this.vesselSearchBaseUrl = AppConfig.marineTraffic.baseUrl;
        this.apiKey = AppConfig.marineTraffic.apiKey;

        this.AppConfig = AppConfig;
    }

    /**
     * Search Marine Traffic for a ship
     * @param  {string} term The search term
     * @return {Promise} A Promise with
     */
    lookupVessel(imo) {
        const config = {
            cache: false,
        };

        let urlQueryParams = `v=5&protocol=jsono&imo=${imo}&msgtype=extended&timespan=180`;

        if (this.AppConfig.urlEncode) {
            urlQueryParams = encodeURIComponent(urlQueryParams);
        }

        const url = `${this.vesselSearchBaseUrl}/api/exportvessel/${this.apiKey}?${urlQueryParams}`;

        // Note: This endpoint defaults to JSON (and is associative)
        // (Unlike all other MT api endpoints)
        return this.$http.get(url, config).then((response) => {
            if (response.status !== 200) {
                return Promise.reject(response.statusText);
            }

            const ships = this.parseShips(response.data);

            return ships.length ? ships[0] : false;
        });
    }

    parseShips(ships) {
        // Marine Traffic can send back XML on errors. Catch these instances, return an empty array
        if (typeof ships === 'string') {
            return [];
        }

        return ships.map((ship) => {
            return {
                lat: parseFloat(ship.LAT),
                lng: parseFloat(ship.LON),
                currentPort: ship.CURRENT_PORT,
                lastPort: ship.LAST_PORT,
                nextPort: ship.NEXT_PORT_NAME,
            };
        });
    }
}
