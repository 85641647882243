export default class
{
    constructor(RareModal, $scope, Rosters) {
        this.RareModal = RareModal;
        this.$scope = $scope;
        this.Rosters = Rosters;
    }

    /**
     * Opens the modal view
     *
     * @return {Promise}
     */
    openModal(vessel = null) {
        // We need to juggle the $scope value manually here because Ionic Modal's don't support
        // controllerAs out of the box. For consistently we set it to `vm` for consistently.
        const modalScope = this.$scope.$new();
        modalScope.vm = this;

        this.vessel = {
            name: vessel ? vessel.name : null,
            imo: vessel ? vessel.imo : null,
            id: vessel ? vessel.id : null,
        };

        this.startDate = moment().set('minute', 0).toDate();
        this.endDate = moment().set('minute', 0).toDate();

        // Handle opening/closing the modal
        this.RareModal.fromTemplateUrl('templates/rosters/add.html', {
            scope: modalScope,
        }).then((modal) => {
            this.modal = modal;
            return this.modal.show();
        });
    }

    /**
     * Closes the modal view
     *
     * @return {Promise}
     */
    closeModal() {
        return this.modal.remove();
    }

    /**
     * Validates the form. On valid data, creates a new Flight and closes the modal
     *
     * @param  {form.FormController} form
     * @return {Promise} Resolving to a boolean whether or not the submission was successful
     */
    submitForm(form) {
        // Return early if form is not valid
        if (!form.$valid) {
            // Set all fields that have errors as dirty so that we can be sure that the correct
            // error states are shown.
            angular.forEach(form.$error, (field) => {
                angular.forEach(field, (errorField) => {
                    errorField.$setDirty();
                });
            });

            return Promise.resolve(false);
        }

        // Create Roster
        const roster = {
            vessel: this.vessel,
            startDate: moment(this.startDate).format(),
            endDate: moment(this.endDate).format(),
        };

        return this.Rosters.store(roster).then(() => {
            this.$scope.$emit('saveRoster', roster);

            return this.closeModal();
        });
    }
}
