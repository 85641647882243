/**
 * Applies an 'activated' class when maching elements are interacted with
 */
export default () => {
    return {
        restrict: 'EC',

        controller($element) {
            $element.on('touchstart', () => {
                $element.addClass('activated');

                const body = angular.element(document.body);
                const touchend = () => {
                    $element.removeClass('activated');
                    body.off('touchend', touchend);
                };

                body.on('touchend', touchend);
            });
        },
    };
};
