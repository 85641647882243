export default class
{
    constructor($scope, countries, countryName) {
        // console.log('countryCodes', countryCodes);

        this.countries = countries;

        this.categories = [];
        this.searchInput = '';

        this.filterCountriesIntoCategories();

        $scope.$on('searchbox:update', (event, input) => {
            this.searchInput = input.toLowerCase();
            this.filterCountriesIntoCategories();
        });
    }

    filterCountriesIntoCategories() {
        this.categories = [];
        const categoriesObject = {};

        const filteredCountries = this.countries.filter((country) => {
            // If we're not searching, don't apply any filters
            if (this.searchInput.length === 0) {
                return true;
            }

            if (!country.title) {
                return false;
            }

            return country.title.toLowerCase().indexOf(this.searchInput) > -1;
        });

        filteredCountries.forEach((country) => {
            if (!country.title || country.title.length === 0) {
                return;
            }

            const firstLetter = country.title[0].toLowerCase();

            if (!categoriesObject[firstLetter]) {
                categoriesObject[firstLetter] = [];
            }

            categoriesObject[firstLetter].push(country);
        });

        Object.keys(categoriesObject).sort().forEach((key) => {
            // We have the countries grouped by the first letter but now need to make sure that
            // each is presented in alphabetical order
            const sortedCountries = categoriesObject[key].sort((a, b) => {
                return a.title.localeCompare(b.title);
            });

            this.categories.push({
                heading: key.toUpperCase(),
                countries: sortedCountries,
            });
        });

        // Make sure the list is always alphabetically sorted
        this.categories = this.categories.sort((a, b) => {
            return a.heading.localeCompare(b.heading);
        });
    }
}
