import homeRoutes from './routes/home';
import inspectorsAndUnionsRoutes from './routes/inspectors-unions';
import vesselsRoutes from './routes/vessels';
import inspectionsRoutes from './routes/inspections';
import resourcesRoutes from './routes/resources';
import rostersRoutes from './routes/rosters';

const routes = ($stateProvider, $urlRouterProvider) => {
    $stateProvider.state('app', {
        url: '/app',
        abstract: true,
        sticky: true,
        views: {
            main: {
                template: '<rare-stack-view name="app"></rare-stack-view>',
            },
        },
    });

    // Import the routes for the App section
    for (const key of Object.keys(homeRoutes)) {
        $stateProvider.state(key, homeRoutes[key]);
    }

    for (const key of Object.keys(inspectorsAndUnionsRoutes)) {
        $stateProvider.state(key, inspectorsAndUnionsRoutes[key]);
    }

    for (const key of Object.keys(vesselsRoutes)) {
        $stateProvider.state(key, vesselsRoutes[key]);
    }

    for (const key of Object.keys(inspectionsRoutes)) {
        $stateProvider.state(key, inspectionsRoutes[key]);
    }

    for (const key of Object.keys(resourcesRoutes)) {
        $stateProvider.state(key, resourcesRoutes[key]);
    }

    for (const key of Object.keys(rostersRoutes)) {
        $stateProvider.state(key, rostersRoutes[key]);
    }

    // if none of the above states are matched, use this as the fallback
    $urlRouterProvider.otherwise('/app/home');
};

export { routes };
