export default class
{
    constructor(RareModal, $scope, $cordovaDialogs) {
        this.$cordovaDialogs = $cordovaDialogs;
        this.RareModal = RareModal;
        this.$scope = $scope;
    }

    updateHoursWorked() {
        const start = moment(this.hours.startDate);
        const end = moment(this.hours.endDate);

        this.hours.hoursWorked = Math.round(moment.duration(end.diff(start)).asHours());
    }

    updateEndTime() {
        console.log('updateEndTime');

        this.hours.endDate = moment(this.hours.startDate).add(this.hours.hoursWorked, 'hours').toDate();
    }

    /**
     * Opens the modal view
     *
     * @return {Promise}
     */
    openModal(roster, hours = null) {
        // We need to juggle the $scope value manually here because Ionic Modal's don't support
        // controllerAs out of the box. For consistently we set it to `vm` for consistently.
        const modalScope = this.$scope.$new();
        modalScope.vm = this;

        this.roster = roster;

        this.hours = hours || {
            startDate: moment().set('minute', 0),
            endDate: moment().set('minute', 0),
            hoursWorked: 0,
        };

        this.hours.startDate = moment(this.hours.startDate).toDate();
        this.hours.endDate = moment(this.hours.endDate).toDate();

        modalScope.$watch('vm.hours.hoursWorked', () => {
            this.updateEndTime();
        });

        // Handle opening/closing the modal
        this.RareModal.fromTemplateUrl('templates/rosters/hours.add.html', {
            scope: modalScope,
        }).then((modal) => {
            this.modal = modal;
            return this.modal.show();
        });
    }

    /**
     * Closes the modal view
     *
     * @return {Promise}
     */
    closeModal() {
        return this.modal.remove();
    }

    saveHours() {
        this.$scope.$emit('saveHours', this.hours, this.roster);
        return this.closeModal();
    }

    deleteHours() {
        this.$cordovaDialogs.confirm(
            'Are you sure you want to delete these hours?',
            'Delete Hours',
            ['Delete', 'Cancel']
        ).then((buttonIndex) => {
            // If the user pressed 'Delete', then remove the marker and close the modal
            if (buttonIndex === 1) {
                this.$scope.$emit('deleteHours', this.hours, this.roster);
                this.closeModal();
            }
        });
    }
}
