export default class
{
    constructor(Ports, $scope, $state, $stateParams, InspectionReport) {
        this.Ports = Ports;
        this.$state = $state;
        this.$scope = $scope;
        this.InspectionReport = InspectionReport;

        this.ports = [];
        this.searchQuery = $stateParams.q;

        $scope.$on('searchbox:update', (event, input) => {
            this.$state.go(this.$state.current, {
                q: input,
                replaceInStackView: true,
            });
        });

        if ($stateParams.q) {
            this.performSearch($stateParams.q);
        } else {
            this.sortPorts();
        }
    }

    selectPort(port) {
        this.InspectionReport.data().port = port;

        setTimeout(() => {
            this.$scope.$emit('stackView:goBack');
        }, 50);
    }

    sortPorts() {
        if (!this.ports) {
            return;
        }

        this.ports = this.ports.sort((portA, portB) => {
            return portA.name.localeCompare(portB.name);
        });
    }

    performSearch(input) {
        this.loading = true;

        this.Ports.search(input).then((ports) => {
            this.ports = ports;
            this.sortPorts();
        }).finally(() => {
            this.loading = false;
        });
    }
}
