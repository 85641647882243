export default () => {
    return [
        {
            char: 'A',
            name: 'Alfa',
            pronounced: 'Al-fah',
        },
        {
            char: 'B',
            name: 'Bravo',
            pronounced: 'Brah-voh',
        },
        {
            char: 'C',
            name: 'Charlie',
            pronounced: 'Char-lee',
        },
        {
            char: 'D',
            name: 'Delta',
            pronounced: 'Del-tah',
        },
        {
            char: 'E',
            name: 'Echo',
            pronounced: 'Eck-oh',
        },
        {
            char: 'F',
            name: 'Foxtrot',
            pronounced: 'Foks-trot',
        },
        {
            char: 'G',
            name: 'Golf',
            pronounced: 'Golf',
        },
        {
            char: 'H',
            name: 'Hotel',
            pronounced: 'Hoh-tel',
        },
        {
            char: 'I',
            name: 'India',
            pronounced: 'In-dee-ah',
        },
        {
            char: 'J',
            name: 'Juliett',
            pronounced: 'Jew-lee-ett',
        },
        {
            char: 'K',
            name: 'Kilo',
            pronounced: 'Key-loh',
        },
        {
            char: 'L',
            name: 'Lima',
            pronounced: 'Lee-mah',
        },
        {
            char: 'M',
            name: 'Mike',
            pronounced: 'Mike',
        },
        {
            char: 'N',
            name: 'November',
            pronounced: 'No-vem-ber',
        },
        {
            char: 'O',
            name: 'Oscar',
            pronounced: 'Oss-cah',
        },
        {
            char: 'P',
            name: 'Papa',
            pronounced: 'Pah-pah',
        },
        {
            char: 'Q',
            name: 'Quebec',
            pronounced: 'Keh-beck',
        },
        {
            char: 'R',
            name: 'Romeo',
            pronounced: 'Row-me-oh',
        },
        {
            char: 'S',
            name: 'Sierra',
            pronounced: 'See-air-rah',
        },
        {
            char: 'T',
            name: 'Tango',
            pronounced: 'Tang-go',
        },
        {
            char: 'U',
            name: 'Uniform',
            pronounced: 'You-nee-form',
        },
        {
            char: 'V',
            name: 'Victor',
            pronounced: 'Vic-tah',
        },
        {
            char: 'W',
            name: 'Whiskey',
            pronounced: 'Wiss-key',
        },
        {
            char: 'X',
            name: 'X-ray',
            pronounced: 'Ecks-ray',
        },
        {
            char: 'Y',
            name: 'Yankee',
            pronounced: 'Yank-key',
        },
        {
            char: 'Z',
            name: 'Zulu',
            pronounced: 'Zoo-loo',
        },
        {
            char: '1',
            name: 'One',
            pronounced: 'Wun',
        },
        {
            char: '2',
            name: 'Two',
            pronounced: 'Too',
        },
        {
            char: '3',
            name: 'Three',
            pronounced: 'Tree',
        },
        {
            char: '4',
            name: 'Four',
            pronounced: 'Fow-er',
        },
        {
            char: '5',
            name: 'Five',
            pronounced: 'Fife',
        },
        {
            char: '6',
            name: 'Six',
            pronounced: 'Six',
        },
        {
            char: '7',
            name: 'Seven',
            pronounced: 'Sev-en',
        },
        {
            char: '8',
            name: 'Eight',
            pronounced: 'Ait',
        },
        {
            char: '9',
            name: 'Nine',
            pronounced: 'Nin-er',
        },
        {
            char: '0',
            name: 'Zero',
            pronounced: 'Zee-ro',
        },
    ];
};
