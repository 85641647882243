export default class
{
    constructor(
        vessel,
        ports,
        reasons,
        types,
        $stateParams,
        $scope,
        InspectionReport,
        InspectionsQueue
    ) {
        this.InspectionsQueue = InspectionsQueue;
        this.$scope = $scope;

        this.vessel = vessel;
        this.ports = ports;
        this.reasons = reasons;
        this.types = types;

        this.inspection = InspectionReport.data();

        if ($stateParams.create) {
            this.inspection = InspectionReport.reset();
            this.inspection.vessel = vessel;
        }
    }

    submitForm() {
        // TODO: Validate we have all the required fields
        console.log('submitForm');

        this.InspectionsQueue.add(this.inspection);
        this.$scope.modal.close();
    }
}
