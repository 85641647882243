export default class
{
    constructor(Database, $q) {
        this.$q = $q;
        this.Database = Database;
    }

    fetch(id) {
        return this.$q.when(this.Database.get('inspections', id));
    }

    all() {
        return this.$q.when(this.Database.from('inspections').list(10000));
    }

    store(inspection) {
        return this.$q.when(this.Database.put('inspections', inspection));
    }

    delete(inspection) {
        const inspectionId = typeof inspection === 'object' ? inspection.id : inspection;

        return this.$q.when(this.Database.remove('inspections', inspectionId));
    }

    /**
     * Clear all records from this store and replace with the items that have been passed in
     *
     * @param  {Array} records Array of objects to store
     * @return {Promise}
     */
    replaceRecords(records) {
        const promise = this.Database.clear('inspections').then(() => {
            return this.Database.putAll('inspections', records);
        });

        return this.$q.when(promise);
    }

    clear() {
        return this.Database.clear('inspections');
    }
}
